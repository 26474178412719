import { useRecoilState } from "recoil";
import { getPosts as APIGetPosts } from "../../actions/getPosts";
import { SocialGetPostsRequestInterface, SocialGetPostsResponseInterface } from "../../models/operation-get-posts";
import { operationGetPostsRequest, operationGetPostsResponse } from "../../recoil-atoms/operation";

export const useViewAllPosts = () : {
	get: SocialGetPostsResponseInterface,
	set: (posts: SocialGetPostsResponseInterface) => void,
	request: (payload: Partial<SocialGetPostsRequestInterface>, payloadFill?: boolean) => Promise<SocialGetPostsResponseInterface>,
} => {
	const [currentPayload, setCurrentPayload] = useRecoilState(operationGetPostsRequest);
	const [posts, setPosts] = useRecoilState(operationGetPostsResponse);

	const httpGetPosts = async (payload: Partial<SocialGetPostsRequestInterface>, payloadFill?: boolean) => {
		const newPayload: Partial<SocialGetPostsRequestInterface> = payloadFill ? Object.assign({}, currentPayload, payload) : payload;

		const response: SocialGetPostsResponseInterface = await APIGetPosts(newPayload as SocialGetPostsRequestInterface)
		setPosts(response);

		setCurrentPayload(newPayload);
		return response;
	};

	return {
		get: posts,
		set: setPosts,
		request: httpGetPosts,
	};
}