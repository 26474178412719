import { useRecoilState } from "recoil";
import { getUsers as APIGetUsers } from "../../actions/getUsers";
import { SocialGetUsersResponseInterface } from "../../models/operation-get-users";
import { operationGetUsersResponse } from "../../recoil-atoms/operation";

export const useGetUsers = () : {
	get: SocialGetUsersResponseInterface,
	set: (posts: SocialGetUsersResponseInterface) => void,
	request: () => Promise<SocialGetUsersResponseInterface>,
} => {
	const [users, setUsers] = useRecoilState(operationGetUsersResponse);

	const httpGetUserData = async () => {
		const resp = await APIGetUsers();

		setUsers(resp);
		return resp;
	};

	return {
		get: users,
		set: setUsers,
		request: httpGetUserData,
	};
}