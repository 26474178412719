import { useState } from "react";
import { updateAnswer } from "../../actions/update";
import { PredefinedAnswerAction, PredefinedAnswersUpdateRequest } from "../../models/payload-parts";

export type UpdateAnswerHook = {
	get: PredefinedAnswerAction,
	set: (posts: PredefinedAnswerAction) => void,
	request: (payload: PredefinedAnswersUpdateRequest) => Promise<PredefinedAnswerAction>,
};

export const useUpdateAnswer = (): UpdateAnswerHook => {
	const [actionResult, setActionResult] = useState({} as PredefinedAnswerAction);

	const httpGetAnswerList = async (payload: PredefinedAnswersUpdateRequest) => {
		const resp: PredefinedAnswerAction = await updateAnswer(payload);

		setActionResult(resp);
		return resp;
	};

	return {
		get: actionResult,
		set: setActionResult,
		request: httpGetAnswerList,
	};
}