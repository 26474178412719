import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Accordion, Button, Card, Container, Divider, Form, Grid, Header, Icon, Label, List, Message, Modal, Segment, Popup } from "semantic-ui-react";
import { changePage } from "../../actions";

import '../css/predef-answers.css';

import { useGetUserData } from "../../social-operation/hooks/services/operation-get-user-data";
import { GetAnswerListHook, useGetAnswerList } from "../hooks/services/get-list";
import { CreateAnswerHook, useCreateAnswer } from "../hooks/services/create";
import { UpdateAnswerHook, useUpdateAnswer } from "../hooks/services/update";
import { DeleteAnswerHook, useDeleteAnswer } from "../hooks/services/delete";

import { useLoadingModal } from "../../social-operation/hooks/operation-loading-modal";
import { useRecoilState } from "recoil";
import { operationLoadingModal } from "../../social-operation/recoil-atoms/operation";
import { OPERATION_REQUEST_STATUS } from "../../social-operation/configs/operation-api";
import { CASES_ISSUE_TYPE } from "../../social-operation/configs/operation-ui";
import { PredefinedAnswerAction, PredefinedAnswersGetListRequest } from "../models/payload-parts";
import { GenericErrorMessage } from "../../social-operation/components/building-blocks";
import { MAX_PREDEF_ANSWER_LENGTH } from "../configs/predef-answers-api";

const PlaceholderInfoPopup = (): JSX.Element => (
	<Popup
		header="Available Placeholders:"
		content={<ul>
			<li><b>{`{{author}}`}</b>: Review Author Name</li>
			<li><b>{`{{latest_app_version}}`}</b>: App Version (Depends on Platform)</li>
		</ul>}
		trigger={(<Button circular icon="info" className="placeholders"/>)}
		position="bottom left"
		size="small"
	/>
);

const NoAnswersPlaceholder = () => (
	<Segment placeholder className="no-answers">
		<Header icon>
			<Icon name="comments outline"/>
			No predefined answer in this category.<br/>
			Add an answer here with the button above.
		</Header>
	</Segment>
);
const RequestSuccessModal = (props: {
	setActionModal: (v: string) => void,
}) => {
	const setActionModal = props.setActionModal;

	return <Modal open={true} className="predef-answers-action small">
		<Modal.Content>
			<Segment>
				<Message
					success
					icon="check circle"
					header={OPERATION_REQUEST_STATUS.SUCCESS.name}
					content="Executed an action."
				/>
			</Segment>
		</Modal.Content>
		<Modal.Actions>
			<Button
				color="red"
				floated="left"
				onClick={() => setActionModal('')}
			>
				<Icon name="x"/>
				Close
			</Button>
		</Modal.Actions>
	</Modal>
};
const RequestErrorModal = (props: {
	setActionModal: (v: string) => void,
}) => {
	const setActionModal = props.setActionModal;

	return <Modal open={true} className="predef-answers-action small">
		<Modal.Content>
			<Segment>
				<GenericErrorMessage message="Could not complete the action. Please try again later."/>
			</Segment>
		</Modal.Content>
		<Modal.Actions>
			<Button
				color="red"
				floated="left"
				onClick={() => setActionModal('')}
			>
				<Icon name="x"/>
				Close
			</Button>
		</Modal.Actions>
	</Modal>
};

const AddAnswerModal = (props: {
	setActionModal  : (v: string) => void,
	answerListHook  : GetAnswerListHook,
	createAnswerHook: CreateAnswerHook,
	sectionIds      : PredefinedAnswersGetListRequest,
}) => {
	const userData     = useGetUserData();
	const loadingModal = useLoadingModal();

	const answerList     = props.answerListHook;
	const createAnswer   = props.createAnswerHook;
	const setActionModal = props.setActionModal;
	const sectionIds     = props.sectionIds;

	const [text, setText] = useState<string>('');

	const requestAnswerCreation = async () => {
		let response = {} as PredefinedAnswerAction;

		setActionModal('');
		loadingModal.addTask('predef-answers-create-answer');

		try {
			if (sectionIds.issue_id && sectionIds.category_id) {
				response = await createAnswer.request({
					agent_id   : userData.get.userId,
					issue_id   : sectionIds.issue_id,
					category_id: sectionIds.category_id,
					answer     : text,
				});
			}

			// refresh list
			await answerList.request({
				issue_id   : sectionIds.issue_id,
				category_id: sectionIds.category_id,
			});
			setActionModal(
				response.status === OPERATION_REQUEST_STATUS.SUCCESS.id ?
				'success': 'error'
			);
		}
		catch (e: unknown) {
			if (!response.status
				|| response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id
			) {
				setActionModal('error');
			}
			else throw e;
		}
		finally {
			loadingModal.removeTask('predef-answers-create-answer');
		}
	};

	return <Modal open={true} className="predef-answers-action small">
		<Modal.Header>
			<Icon name="plus circle"/> Add an Answer
		</Modal.Header>
		<Modal.Content scrolling={false}>
			<Form>
				<Form.TextArea
					placeholder="(Your Predefined Answer)"
					value={text}
					rows={5}
					maxLength={MAX_PREDEF_ANSWER_LENGTH}
					onChange={(e, target) => setText(target.value as string)}
				/>
				<Label
					icon="bars"
					content={MAX_PREDEF_ANSWER_LENGTH as number - text.length}
				/>
			</Form>
		</Modal.Content>
		<Modal.Actions>
			<Button
				floated="left"
				onClick={() => setActionModal('')}
			>
				<Icon name="x"/>
				Cancel
			</Button>
			<Button
				color="green"
				onClick={() => requestAnswerCreation()}
				disabled={!text || !text.length}
			>
				<Icon name="add"/>
				Add
			</Button>
		</Modal.Actions>
	</Modal>
};
const EditAnswerModal = (props: {
	setActionModal  : (v: string) => void,
	answerListHook  : GetAnswerListHook,
	updateAnswerHook: UpdateAnswerHook,
	sectionIds      : PredefinedAnswersGetListRequest,
	answerId        : string,
}) => {
	const userData     = useGetUserData();
	const loadingModal = useLoadingModal();

	const answerList     = props.answerListHook;
	const updateAnswer   = props.updateAnswerHook;
	const setActionModal = props.setActionModal;
	const sectionIds     = props.sectionIds;
	const answerId       = props.answerId;

	const [text, setText]               = useState<string>('');
	const [currentText, setCurrentText] = useState<string>('');

	useLayoutEffect(() => {
		const answer = answerList.get.find((item) => item.answer_id === answerId)?.answer ?? '';
		setText(answer);
		setCurrentText(answer);
	}, []);

	const requestAnswerUpdate = async () => {
		let response = {} as PredefinedAnswerAction;

		setActionModal('');
		loadingModal.addTask('predef-answers-edit-answer');

		try {
			response = await updateAnswer.request({
				agent_id   : userData.get.userId,
				answer_id  : answerId,
				issue_id   : sectionIds.issue_id,
				category_id: sectionIds.category_id,
				answer     : text,
			});

			// refresh list
			await answerList.request({
				issue_id   : sectionIds.issue_id,
				category_id: sectionIds.category_id,
			});
			setActionModal(
				response.status === OPERATION_REQUEST_STATUS.SUCCESS.id ?
				'success': 'error'
			);
		}
		catch (e: unknown) {
			if (!response.status
				|| response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id
			) {
				setActionModal('error');
			}
			else throw e;
		}
		finally {
			loadingModal.removeTask('predef-answers-edit-answer');
		}
	};

	return <Modal open={true} className="predef-answers-action small">
		<Modal.Header>
			<Icon name="pencil"/> Edit an Answer
		</Modal.Header>
		<Modal.Content scrolling={false}>
			<Form>
				<Form.TextArea
					placeholder="(Your Predefined Answer)"
					value={text}
					rows={5}
					maxLength={MAX_PREDEF_ANSWER_LENGTH}
					onChange={(e, target) => setText(target.value as string)}
				/>
				<Label
					icon="bars"
					content={MAX_PREDEF_ANSWER_LENGTH as number - text.length}
				/>
			</Form>
		</Modal.Content>
		<Modal.Actions>
			<Button
				floated="left"
				onClick={() => setActionModal('')}
			>
				<Icon name="x"/>
				Cancel
			</Button>
			<Button
				primary
				onClick={() => requestAnswerUpdate()}
				disabled={!text || !text.length || currentText === text}
			>
				<Icon name="pencil"/>
				Edit
			</Button>
		</Modal.Actions>
	</Modal>
};
const DeleteAnswerModal = (props: {
	setActionModal  : (v: string) => void,
	answerListHook  : GetAnswerListHook,
	deleteAnswerHook: DeleteAnswerHook,
	sectionIds      : PredefinedAnswersGetListRequest,
	answerId        : string,
}) => {
	const userData     = useGetUserData();
	const loadingModal = useLoadingModal();

	const answerList     = props.answerListHook;
	const deleteAnswer   = props.deleteAnswerHook;
	const setActionModal = props.setActionModal;
	const sectionIds     = props.sectionIds;
	const answerId       = props.answerId;

	const requestAnswerDeletion = async () => {
		let response = {} as PredefinedAnswerAction;

		setActionModal('');
		loadingModal.addTask('predef-answers-delete-answer');

		try {
			response = await deleteAnswer.request({
				agent_id : userData.get.userId,
				answer_id: answerId,
			});

			// refresh list
			await answerList.request({
				issue_id   : sectionIds.issue_id,
				category_id: sectionIds.category_id,
			});
			setActionModal(
				response.status === OPERATION_REQUEST_STATUS.SUCCESS.id ?
				'success': 'error'
			);
		}
		catch (e: unknown) {
			if (!response.status
				|| response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id
			) {
				setActionModal('error');
			}
			else throw e;
		}
		finally {
			loadingModal.removeTask('predef-answers-delete-answer');
		}
	};

	return <Modal open={true} className="predef-answers-action small">
		<Modal.Header>
			<Icon name="trash"/>
			Confirm Predefined Answer Deletion
		</Modal.Header>
		<Modal.Content>
			<Segment basic textAlign="center" size="big">
				<i>{answerList.get.find((item) => item.answer_id === answerId)?.answer ?? ''}</i>
			</Segment>
		</Modal.Content>
		<Modal.Actions>
			<Button
				floated="left"
				onClick={() => setActionModal('')}
			>
				<Icon name="x"/>
				Cancel
			</Button>
			<Button
				color="red"
				onClick={() => requestAnswerDeletion()}
			>
				<Icon name="trash"/>
				Delete
			</Button>
		</Modal.Actions>
	</Modal>
};

const PageHeader = (props: {
	setActionModal: (v: string) => void
}) => {
	return <Header as="h1" className="page-header">
		<span>
			<Icon name="comments"/>
			Customize Predefined Answers
		</span>
		<PlaceholderInfoPopup/>
		<Button
			color="green"
			circular
			floated="right"
			onClick={() => props.setActionModal('create')}
		>
			<Icon name="add"/>
			Add an Answer
		</Button>
	</Header>;
}
const CategoryMenuList = (props: {
	answerListHook: GetAnswerListHook,
	sectionIds    : PredefinedAnswersGetListRequest,
	setSectionIds : (v: PredefinedAnswersGetListRequest) => void,
}) => {
	const answerList    = props.answerListHook;
	const sectionIds    = props.sectionIds;
	const setSectionIds = props.setSectionIds;

	const loadingModal = useLoadingModal();

	const [activeMenu, setActiveMenu] = useState<string>('');

	const subMenuEventHandler = async (params: {
		issue      : string,
		category   : string,
		issue_id   : number,
		category_id: number,
	}) => {
		const newSection = {
			issue_id   : params.issue_id,
			category_id: params.category_id,
		};

		loadingModal.addTask("predef-answers-get-list");
		setSectionIds(newSection);
		await answerList.request(newSection);
		loadingModal.removeTask("predef-answers-get-list");
	}

	useEffect(() => {
		const key = Object.values(CASES_ISSUE_TYPE).find(e => e.value === sectionIds.issue_id)?.key;
		if (key) {
			setActiveMenu(key);
		}
	},[]);

	return <Segment basic className="menu">
		<Accordion
			styled
		>
			{Object.values(CASES_ISSUE_TYPE).map(issue => [
				<Accordion.Title
					key={`${issue.key}-title`}
					active={activeMenu === issue.key}
					onClick={() => setActiveMenu(issue.key)}
				>
					{issue.text}
				</Accordion.Title>,
				<Accordion.Content
					key={`${issue.key}-content`}
					active={activeMenu === issue.key}
				>
					<List celled className="sub-menu">
						{issue.categories.map(categ =>
							<List.Item
								key={`${issue.key}-${categ.key}`}
								active={sectionIds.issue_id === issue.value
									&& sectionIds.category_id === categ.value
								}
								onClick={() => subMenuEventHandler({
									issue      : issue.key,
									category   : categ.key,
									issue_id   : issue.value,
									category_id: categ.value,
								})}
							>
								<List.Header
									active={sectionIds.issue_id === issue.value
										&& sectionIds.category_id === categ.value
									}
								>{categ.text}</List.Header>
								<List.Icon
									name="angle right"
									className="active-icon"
									hidden={sectionIds.issue_id !== issue.value
										|| sectionIds.category_id !== categ.value}
								/>
							</List.Item>
						)}
					</List>
				</Accordion.Content>
			])}
		</Accordion>
	</Segment>;
}
const AnswerCardList = (props: {
	setActionModal: (v: string) => void,
	answerListHook: GetAnswerListHook,
	setAnswerId   : (v: string) => void,
}) => {
	const setActionModal = props.setActionModal;
	const answerList     = props.answerListHook;
	const setAnswerId    = props.setAnswerId;

	return <Segment basic className="answer-list">
		{answerList.get.length ?
			answerList.get.map((item) =>
				<Card key={item.answer_id}>
					<Card.Content>
						<div className="answer">{item.answer}</div>
						<div className="action-button-list">
							<Button
								color="red"
								size="small"
								className="delete"
								circular
								icon="trash"
								onClick={() => {
									setAnswerId(item.answer_id);
									setActionModal('delete');
								}}
							/>
							<Button
								primary
								size="small"
								className="edit"
								circular
								icon="pencil"
								onClick={() => {
									setAnswerId(item.answer_id);
									setActionModal('edit');
								}}
							/>
						</div>
					</Card.Content>
				</Card>
			): <NoAnswersPlaceholder/>
		}
	</Segment>;
}

const AnswersConfigMainPage = (props: {
	showLoading: () => void,
	hideLoading: () => void
}) => {
	const [loadingModalHandlers, setLoadingModalHandlers] = useRecoilState(operationLoadingModal);

	const userData     = useGetUserData();
	const answerList   = useGetAnswerList();
	const createAnswer = useCreateAnswer();
	const updateAnswer = useUpdateAnswer();
	const deleteAnswer = useDeleteAnswer();

	const [activeActionModal, setActiveActionModal]
		= useState<string>(''); // 'create' | 'edit' | 'delete' | 'error'

	const loadingModal = useLoadingModal();

	const [sectionIds, setSectionIds] = useState<PredefinedAnswersGetListRequest>({
		issue_id   : CASES_ISSUE_TYPE.INQUIRY.value,
		category_id: CASES_ISSUE_TYPE.INQUIRY.categories[0].value,
	});
	const [activeAnswerId, setActiveAnswerId] = useState<string>('');

	const pageInit = async () => {
		loadingModal.addTask('predef-answers-init');
		await Promise.all([
			userData.request(),
			answerList.request(sectionIds),
		]);
		loadingModal.removeTask('predef-answers-init');
	};

	useLayoutEffect(() => {
		if (!loadingModalHandlers) {
			setLoadingModalHandlers({
				showLoading: props.showLoading,
				hideLoading: props.hideLoading
			});
		}
	}, []);
	useEffect(() => {
		if (loadingModalHandlers.showLoading)
			pageInit();
	}, [loadingModalHandlers]);

	return <Segment className="predef-answers">
		<PageHeader
			setActionModal={setActiveActionModal}
		/>
		<Divider/>
		<Grid container divided="vertically">
			<Grid.Column>
				<CategoryMenuList
					answerListHook={answerList}
					sectionIds={sectionIds}
					setSectionIds={setSectionIds}
				/>
			</Grid.Column>
			<Grid.Column>
				<Divider vertical section content=">" />
			</Grid.Column>
			<Grid.Column>
				<AnswerCardList
					setActionModal={setActiveActionModal}
					answerListHook={answerList}
					setAnswerId={setActiveAnswerId}
				/>
			</Grid.Column>
		</Grid>
		{(() => {
			switch (activeActionModal) {
				case 'create':
					return <AddAnswerModal
						setActionModal={setActiveActionModal}
						answerListHook={answerList}
						createAnswerHook={createAnswer}
						sectionIds={sectionIds}
					/>;
				case 'edit':
					return <EditAnswerModal
						setActionModal={setActiveActionModal}
						answerListHook={answerList}
						updateAnswerHook={updateAnswer}
						sectionIds={sectionIds}
						answerId={activeAnswerId}
					/>;
				case 'delete':
					return <DeleteAnswerModal
						setActionModal={setActiveActionModal}
						answerListHook={answerList}
						deleteAnswerHook={deleteAnswer}
						sectionIds={sectionIds}
						answerId={activeAnswerId}
					/>;
				case 'success':
					return <RequestSuccessModal
						setActionModal={setActiveActionModal}
					/>;
				case 'error':
					return <RequestErrorModal
						setActionModal={setActiveActionModal}
					/>;
				default: return '';
			}
		})()}
	</Segment>;
};

// container class component wrap + redux adaptation
class AnswersConfigPage extends React.Component<{
	showLoading: () => void,
	hideLoading: () => void
}> {
	render = () => {
		return (
			<Container className="page-content">
				<AnswersConfigMainPage
					showLoading={this.props.showLoading}
					hideLoading={this.props.hideLoading}
				/>
			</Container>
		);
	};
};

export default connect(null, { changePage })(AnswersConfigPage);