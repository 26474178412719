export const toDateString = (date: Date): string => [date.getFullYear(), (date.getMonth() + 1).toString().padStart(2, '0'), date.getDate()].join('-');
export const getRemainingSLAString = (seconds: number): string => {
	const h: string = Math.floor(seconds / 3600).toString().padStart(1, '0');
	const m: string = Math.floor((seconds / 60) % 60).toString().padStart(2, '0');
	const s: string = Math.floor(seconds % 60).toString().padStart(2, '0');
	let str = '';

	str += h ? h + ':' : '';
	str += m ? m + ':' : '';
	str += s ? s : '';

	return str;
};