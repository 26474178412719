import React from "react";
import _ from 'lodash';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
  Label as RCLabel,
  Text
} from "recharts";

import { Card, Label, Icon, Tab, Grid, Divider, Segment, Header, SemanticWIDTHS } from "semantic-ui-react";
import { Container, Row } from "react-bootstrap";
import { constSelector } from "recoil";

const SOURCE_COLORS: any = {
  facebook: "#2F329F",
  pantip: "#6D1C77",
  twitter: "#B8206A",
}; //facebook,pantip,twitter

class OverallBarChart extends React.Component<any> {

  panes = [
    { menuItem: 'Type 1', render: () => <Tab.Pane>{this.renderBarChart_1()}</Tab.Pane> },
    { menuItem: 'Type 2', render: () => <Tab.Pane>{this.renderBarChart_2()}</Tab.Pane> },
    { menuItem: 'Type 3', render: () => <Tab.Pane>{this.renderBarChart_3()}</Tab.Pane> },
  ]

  state = { isShowSuggest: false, currentTab: 0, eachKeywordData: [], dataTotalForC2: [{}], dataTotalForC3: [{}], totalForC2: { "all": 0 } };
  [key: string]: any;
  componentDidMount() {
    this.ref = React.createRef();
    this.transformData()
  }

  componentDidUpdate(prevProps: any) {
    if (prevProps !== this.props) {
      this.transformData()
    }
  }

  transformData = () => {
    const allKeys = Object.keys(this.props.data[0]);
    const keywords = allKeys.filter((ele) => !(["name", "amt"].includes(ele)));

    const srcStat: Readonly<Array<Object>> = keywords.map((key) => {
      const total = this.props.data.reduce((sum: number, ele: any) => (sum + ele[key]), 0)
      return { "name": key, "total": total }
    })

    const sumAll: number = _.sumBy(srcStat, "total")
    let total: { [key: string]: number } = { "all": sumAll }

    const eachKeywordData: Array<Object> = this.props.data.map((ele: any) => {
      return {
        name: ele.name,
        data: keywords.map((key) => { return { name: key, total: ele[key] } }),
        amt: ele.amt,
        color: this.props.colors[ele.name.toLowerCase()]
      }
    })

    const dataTotalForC3 = keywords.map((key) => {
      let ret: any = { name: key }
      this.props.data.forEach((bank: any) => {
        ret[bank.name] = bank[key]
      })
      return ret;
    })

    this.setState({ eachKeywordData: eachKeywordData, dataTotalForC3: dataTotalForC3, dataTotalForC2: srcStat, totalForC2: total })
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderBar = () => {
    let bars: any = [];
    Object.keys(this.props.data[0]).forEach((ele, idx) => {
      if (ele !== "name" && ele !== "amt") {
        bars.push(
          <Bar
            isAnimationActive={true}
            key={`barkey_${idx}`}
            dataKey={ele}
            stackId="a"
            fill={SOURCE_COLORS[ele]}
          />
        );
      }
    });
    return bars;
  };

  renderBarChart_1 = () => {
    return (
      <BarChart
        width={1000}
        height={300}
        data={this.props.data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: this.props.dispSources,
              related: param.activeLabel,
              sentiment: this.props.dispSentiments,
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
        style={{ cursor: "pointer" }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {this.renderBar()}
      </BarChart>
    );
  };

  renderAllChartsC2 = (data: any) => {
    return data.map((ele: any, idx: number) => (<Grid.Column>
      <BarChart key={`Bar2_IDX${idx}`} width={100} height={300} data={ele.data} layout="vertical" margin={{
        top: 30,
        right: 30,
        left: 10,
        bottom: 30,
      }}
        style={{ cursor: "pointer" }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: param.activeLabel,
              related: ele.name,
              sentiment: this.props.dispSentiments,
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}>
        <XAxis hide={false} type="number" axisLine={false} tickLine={false} tick={false}>
          <RCLabel value={`(${ele.amt})`} offset={-10} position="insideBottom" />
          <RCLabel value={ele.name} offset={220} position="top" />
        </XAxis>
        <YAxis hide={true} dataKey="name" type="category" />
        <Tooltip />
        <Bar dataKey="total" fill={ele.color} label={({ payload, x, y, width, height, value }: any) => {
          return <text x={x + width} y={y + 10 + height / 2} fill="#666" textAnchor="left" dy={-6}>{`${Math.round(value * 100 / ele.amt)}%`}</text>;
        }} />
      </BarChart>
    </Grid.Column>))
  }

  renderBarChart_2 = () => {
    const keywordCount: SemanticWIDTHS = this.state.eachKeywordData.length as SemanticWIDTHS;
    return (
      <Grid textAlign='center' padded={false} divided style={{ width: "100%" }}>
        <Grid.Row>
          <Grid.Column width={5}>
            <ResponsiveContainer>
              <ComposedChart width={100} height={300} data={this.state.dataTotalForC2} layout="vertical" margin={{
                top: 30,
                right: 30,
                left: 10,
                bottom: 30,
              }}
                style={{ cursor: "pointer" }}
                onClick={(param) => {
                  if (param) {
                    const bigTableFilter = {
                      source: param.activeLabel,
                      related: this.props.dispTargets,
                      sentiment: this.props.dispSentiments,
                    };
                    this.props.setToggleShowBigTableWithFilter(bigTableFilter);
                  }
                }}>
                <XAxis hide={false} type="number" axisLine={false} tickLine={false} tick={false}>
                  <RCLabel value={`(${this.state.totalForC2["all"]})`} offset={-10} position="insideBottom" />
                  <RCLabel value="Total" offset={220} position="top" />
                </XAxis>
                <YAxis dataKey="name" type="category" />
                <Tooltip />
                <Bar dataKey="total" fill="#b6bbc2" label={({ payload, x, y, width, height, value }: any) => {
                  return <text x={x + width} y={y + 10 + height / 2} fill="#666" textAnchor="left" dy={-6}>{`${Math.round(value * 100 / this.state.totalForC2["all"])}%`}</text>;
                }} />
              </ComposedChart>
            </ResponsiveContainer>
          </Grid.Column>

          <Grid.Column width={11}>
            <Grid columns={keywordCount} textAlign='left' padded={false} stackable>
              <Grid.Row>
                {this.renderAllChartsC2(this.state.eachKeywordData)}
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  };

  renderAllChartsC3 = (data: any) => {
    return data.map((bank: any, idx: number) => {
      return (
        <Bar key={`Bar3_IDX${idx}`} dataKey={bank} fill={this.props.colors[bank.toLowerCase()]} />
      )
    })
  }

  renderBarChart_3 = () => {
    return (
      <BarChart
        width={1000}
        height={300}
        data={this.state.dataTotalForC3}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
        style={{ cursor: "pointer" }}
        onClick={(param) => {
          if (param) {
            const bigTableFilter = {
              source: param.activeLabel,
              related: this.props.dispTargets,
              sentiment: this.props.dispSentiments,
            };
            this.props.setToggleShowBigTableWithFilter(bigTableFilter);
          }
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {this.renderAllChartsC3(Object.keys(this.state.dataTotalForC3[0]).filter((ele) => (ele !== "name")))}
      </BarChart>
    );
  };

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            Overall Bank Mention
            <div
              style={{
                display: "inline-block",
                float: "right",
              }}
            >
              {this.renderClickToSaveSuggestion()}
              <Icon
                name="save"
                floated="right"
                size="small"
                style={{ cursor: "pointer" }}
                onMouseEnter={this.showSuggestion}
                onMouseLeave={this.hideSuggestion}
                onClick={() => {
                  this.props.exportAsJPEG(
                    this.ref,
                    "overAllBankMention.jpeg",
                    "#FFFFFF"
                  );
                }}
              />
            </div>
          </Card.Header>
          <Card.Meta>Overall mention count each bank</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Container ref={this.ref}>
            <Row>
              <Tab
                panes={this.panes}
                activeIndex={this.state.currentTab}
                renderActiveOnly={true}
                onTabChange={(event: any, data: any) => {
                  this.setState({ currentTab: data.activeIndex });
                }}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
                style={{ width: "100%" }}
              /></Row>
          </Container>
        </Card.Content>
      </Card>
    );
  }
}

export default OverallBarChart;
