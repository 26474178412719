import { useState } from "react";
import { claim as APIClaim } from "../../actions/claim";
import { SocialClaimRequestInterface, SocialClaimResponseInterface } from "../../models/operation-claim";

export const useClaim = () : {
	get: SocialClaimResponseInterface,
	set: (posts: SocialClaimResponseInterface) => void,
	request: (payload: SocialClaimRequestInterface) => Promise<SocialClaimResponseInterface>,
} => {
	const [claim, setClaim] = useState({} as SocialClaimResponseInterface);

	const httpGetClaim = async (payload: SocialClaimRequestInterface) => {
		const resp = await APIClaim(payload);

		setClaim(resp);
		return resp;
	};

	return {
		get: claim,
		set: setClaim,
		request: httpGetClaim,
	};
}