import { useRecoilState } from "recoil";
import { getCaseDetails as APIGetCaseDetails } from "../../actions/getCaseDetails";
import { SocialGetCaseDetailsRequestInterface, SocialGetCaseDetailsResponseInterface } from "../../models/operation-get-case-details";
import { operationCaseDetailsResponse } from "../../recoil-atoms/operation";

export const useViewCaseDetails = () : {
	get: SocialGetCaseDetailsResponseInterface,
	set: (caseDetails: SocialGetCaseDetailsResponseInterface) => void,
	request: (payload: SocialGetCaseDetailsRequestInterface) => Promise<SocialGetCaseDetailsResponseInterface>,
} => {
	const [caseDetails, setCaseDetails] = useRecoilState(operationCaseDetailsResponse);

	const httpGetCaseDetails = async (payload: SocialGetCaseDetailsRequestInterface) => {
		const resp = await APIGetCaseDetails(payload);

		setCaseDetails(resp);
		return resp;
	};

	return {
		get: caseDetails,
		set: setCaseDetails,
		request: httpGetCaseDetails,
	};
}