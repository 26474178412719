import { FlatObject, RecoilAtom } from "../models/known-types";

export const stackAtomPush = (
	atom: RecoilAtom<FlatObject[]>,
	value: FlatObject | FlatObject[]
): void => {
	const [get, set] = atom;
	const stack: FlatObject[] = get.map((e: FlatObject) => e);
	
	if (!Array.isArray(value))
		stack.push(value);
	else
		stack.concat(value);
	set(stack);
}
export const stackAtomPop = (
	atom: RecoilAtom<FlatObject[]>,
	entries: (number | 'all') = 1
): FlatObject[] => {
	const [get, set] = atom;
	const stack: FlatObject[] = get.map((e: FlatObject) => e);
	
	if (entries === 'all') {
		set([]);
		return stack;
	}

	const poppedStack: FlatObject[] = stack.splice(-entries);
	set(stack);
	return poppedStack;
}
export const stackAtomPeek = (getter: FlatObject[]): FlatObject| null => getter.length ? getter[getter.length - 1] : null;