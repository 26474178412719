import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError, WrappedResponse } from "../../models/http";
import { SocialClaimRequestInterface, SocialClaimResponseInterface } from "../models/operation-claim";
import { OPERATION_ROUTE } from "../configs/operation-api";

export const claim = async (payload: SocialClaimRequestInterface): Promise<SocialClaimResponseInterface> => {
	const response =
		await axios.post
			<WrappedResponse<SocialClaimResponseInterface>>(
				OPERATION_ROUTE.CLAIM_POST,
				payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data.responsePayload;
};