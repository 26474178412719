import React, { useEffect, useLayoutEffect, useState } from "react";

import '../css/operation.css';

import {
	Accordion,
	Button,
	Comment,
	Container,
	Form,
	Header,
	Icon,
	Label,
	List,
	Message,
	Popup,
	Rating,
	Segment,
	Tab
} from "semantic-ui-react";
import {
	DataTable,
	DatePickerButton,
	GenericErrorMessage,
	RatingRangeSelect,
	ReloadPageButton
} from "./building-blocks";

import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState
} from "recoil";
import {
	operationActionModalStack as actionModalStack,
	operationActionDataStack as actionDataStack,
	operationPostsPlatform as postsPlatform,
	operationReplyForm,
	operationPostsColumnLabels as postsColumnLabels,
	operationGetPostsRequest
} from "../recoil-atoms/operation";
import {
	SocialPostsDataTableInterface,
	SocialPostDetailsInterface,
	SocialGetPostsResponseInterface
} from "../models/operation-get-posts";
import { useViewAllPosts } from "../hooks/services/operation-get-posts";
import { useDateRange } from "../hooks/operation-date-range";
import { stackAtomPeek, stackAtomPush } from "../utils/recoil-helpers";
import {
	POSTS_DEFAULT_REQUEST,
	OPERATION_REQUEST_STATUS,
	OPERATION_STATE,
	OPERATION_ACTION,
	POSTS_VALUE_TYPES
} from "../configs/operation-api";
import {
	SOCIAL_PLATFORMS,
	TABLE_PAGELENGTH,
	OPERATION_USER_ROLE
} from "../configs/operation-ui";
import { PageArgsInterface } from "../models/data-table";
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { getRemainingSLAString } from "../utils/string-formatters";
import { useClaim } from "../hooks/services/operation-claim";
import { SocialReplyFormDataInterface } from "../models/operation-submit-action";
import { useGetUserData } from "../hooks/services/operation-get-user-data";
import { useGetUsers } from "../hooks/services/operation-get-users";
import { useViewCaseDetails } from "../hooks/services/operation-get-case-details";
import { TakeActionModalActions, TakeActionModalContent } from "./operation-cases";
import { SearchCriteriasAccordion } from "./search-criterias";
import {
	SocialStringFilterOptions,
	SocialDateStringFilterOptions,
	SocialTimeFilterOptions,
	SocialNumberFilterOptions,
	SocialEnumFilterOptions,
	SocialSearchCriterias
} from "../models/filters-sort";
import {
	FlatObject,
	RecoilAtom,
	StateHook
} from "../models/known-types";
import { DateRangeInterface } from "../models/date-range";

const PostInfoPopup = (): JSX.Element => (
	<Segment basic className="operation-info">
		<Popup
			header="Quick Guides: Posts"
			content={
				<ul>
					<li>Filters are also applied upon setting a new date range.</li>
					<li>Hovering over shortened text reveals full text in a tooltip.</li>
					<li>The remaining SLA time is formatted in <b>h:mm:ss</b>.</li>
					<li>In some channels, rows are colorized depending on the remaining SLA time.
						<ul>
							<li><div className="sla-green sample"/>: &gt; 2 Hours</li>
							<li><div className="sla-yellow sample"/>: &gt; 1 Hour</li>
							<li><div className="sla-red sample"/>: &lt; 1 Hour</li>
						</ul>
					</li>
				</ul>
			}
			trigger={(<Button circular icon="info"/>)}
			position="bottom right"
			size="small"
		/>
	</Segment>
);
const PostsAdvancedSearchPanel = (props: {
	postDateRange?: StateHook<DateRangeInterface>
}): JSX.Element => {
	const [currentPayload, setCurrentPayload] = useRecoilState(operationGetPostsRequest);
	const tableColumnLabels = useRecoilValue(postsColumnLabels);
	const [criterias, setCriterias] = useState<SocialSearchCriterias>();

	const filterChangeHandler = (
		newFilter :
			SocialStringFilterOptions |
			SocialDateStringFilterOptions |
			SocialTimeFilterOptions |
			SocialNumberFilterOptions |
			SocialEnumFilterOptions,
		enableFilter: boolean
	) => {
		const ft = {...newFilter, id: POSTS_VALUE_TYPES.find(e => e.id === newFilter.id)?.alt_id as string };
		const index = criterias?.filters?.findIndex(e => e.id === ft.id) ?? -1;

		if (enableFilter) {
			if (index === -1) {
				setCriterias(Object.assign(
					{},
					criterias,
					{ filters: criterias?.filters?.concat(ft) ?? [ft] }
				));
			}
			else if (criterias && criterias.filters?.length) {
				const newFilters = [ ...criterias.filters ];
				newFilters[index] = ft;
				const newCriterias = { ...criterias, filters: newFilters };
				setCriterias(newCriterias);
				setCurrentPayload({ ...currentPayload, criterias: newCriterias });
			}
		}
		else if (criterias && criterias.filters?.length && index > -1) {
			const newFilters = [ ...criterias.filters ];
			newFilters.splice(index, 1);
			const newCriterias = { ...criterias, filters: newFilters };
			setCriterias(newCriterias);
			setCurrentPayload({ ...currentPayload, criterias: newCriterias });
		}
	}

	return <SearchCriteriasAccordion
		valueTypes={POSTS_VALUE_TYPES
			.filter(e => tableColumnLabels[e.id])
			.map(e => {
				switch (e.id) {
					case 'postDate':
						return Object.assign(e, {
							label: tableColumnLabels[e.id],
							onChange: filterChangeHandler,
							externalHook: props.postDateRange
						});
					default:
						return Object.assign(e, {
							label: tableColumnLabels[e.id],
							onChange: filterChangeHandler
						});
				}
			})
		}
		onSortingChange={(sort) => {
			const match = POSTS_VALUE_TYPES.find(e => e.id === sort.id)?.alt_id;
			sort.id = match ? match: '';
			const newCriterias = Object.assign({}, criterias, { sort: sort });
			setCriterias(newCriterias);
			setCurrentPayload({ ...currentPayload, criterias: newCriterias });
		}}
	/>;
};
const PostSearchForm = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [dateRange, setDateRange] = useDateRange({
		startDate: POSTS_DEFAULT_REQUEST.from as Date,
		endDate: POSTS_DEFAULT_REQUEST.to as Date
	});
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);

	const posts = useViewAllPosts();

	const [query, setQuery] = useState('');
	const [initialized, setInitialized] = useState(false);
	const [minRating, setMinRating] = useState(POSTS_DEFAULT_REQUEST.filter.min_rating);
	const [maxRating, setMaxRating] = useState(POSTS_DEFAULT_REQUEST.filter.max_rating);

	const applyPostFilters = async () => {
		loadingModal.addTask('getPosts');

		const payload: NodeJS.Dict<unknown> = {
			q: query,
			from: dateRange.startDate,
			to: dateRange.endDate,
			offset_start: POSTS_DEFAULT_REQUEST.offset_start,
			offset_end: POSTS_DEFAULT_REQUEST.offset_end,
			filter: {
				min_rating: minRating,
				max_rating: maxRating
			}
		};
		if (!posts.get.total && !initialized) {
			setInitialized(true);
			payload.source = POSTS_DEFAULT_REQUEST.source;
		}

		const response = await posts.request(payload, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getPostsError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}

		loadingModal.removeTask('getPosts');
	};

	return (
		<Segment secondary>
			<Header as="h2">Search Reviews</Header>
			<Form className="operation-search-form">
				<Form.Group>
					<Form.Input icon="search" fluid label="Query" onChange={e => setQuery(e.target.value)} />
					<RatingRangeSelect
						label="Filter by Rating"
						min={minRating}
						max={maxRating}
						onChange={(min, max) => {
							setMinRating(min);
							setMaxRating(max);
						}}
					/>
					<Form.Field className="operation-date-label">
						<Label pointing="right">
							{'From: ' + dateRange.startDate.toLocaleDateString()}<br/>
							{'To: ' + dateRange.endDate.toLocaleDateString()}
						</Label>
					</Form.Field>
					<DatePickerButton
					 	inForm
						defaultRange={{
							startDate: POSTS_DEFAULT_REQUEST.from as Date,
							endDate: POSTS_DEFAULT_REQUEST.to as Date
						}}
						range={[dateRange, setDateRange]}
						onConfirm={applyPostFilters}
						confirmButtonContent={<><Icon name="search"/>Set & Search</>}
					>
						Date Range
					</DatePickerButton>
					<Form.Button
						className="search-button"
						color="green"
						icon="search"
						content="Search"
						onClick={() => applyPostFilters()}
					/>
				</Form.Group>
			</Form>
			<PostsAdvancedSearchPanel
				postDateRange={[dateRange, setDateRange]}
			/>
		</Segment>
	);
};
const PostResultsInfo = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);

	const posts = useViewAllPosts();

	const reload = async () => {
		loadingModal.addTask('getPosts');

		const response = await posts.request({}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getPostsError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}
		
		loadingModal.removeTask('getPosts');
	};

	return (
		<Segment basic className="operation-results-info">
			<b>Total Results: </b>
			<Label>{posts.get.total}</Label>
			<Button basic circular floated="right" icon="refresh" onClick={reload}/>
		</Segment>
	);
};
const PostPlatformButtonGroup = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [platform, setPlatform] = useRecoilState(postsPlatform);
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);

	const posts = useViewAllPosts();

	const setPlatformButtonActive = (id: string) => platform === id ? 'active ' + id : '';
	const onPlatformChange = async (id: string) => {
		if (platform !== id) {
			loadingModal.addTask('getPosts');

			setPlatform(id);
			const response = await posts.request({
				source: id,
				offset_start: POSTS_DEFAULT_REQUEST.offset_start,
				offset_end: POSTS_DEFAULT_REQUEST.offset_end
			}, true);
			if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
				setDataStack([{
					action: 'getPostsError',
					className: 'smaller',
					data: null,
					locked: true
				}]);
				setModalStack([{
					content: <GenericErrorMessage message={response.message}/>,
					actions: <ReloadPageButton/>
				}]);
			}
			
			loadingModal.removeTask('getPosts');
		}
	};

	useEffect(() => setPlatform(Object.values(SOCIAL_PLATFORMS)[0].id), []);

	return (
		<Button.Group className='operation-platform-buttons' attached='top' color='grey' labeled icon>
			{Object.values(SOCIAL_PLATFORMS).map((p, i) =>
				<Button
					key={i}
					className={setPlatformButtonActive(p.id)}
					onClick={() => onPlatformChange(p.id)}
					icon={p.icon}
					content={p.name}
				/>
			)}
		</Button.Group>
	);
};
const ReviewLayout = ({author, date, text, rating, ...props}: {
	author: string,
	date: string,
	text: string,
	rating: string | number,
	[key: string]: unknown
}): JSX.Element => (
	<Comment.Group
		as={Segment}
		fluid="true"
		className="operation-review-layout"
		size="large"
		{...props}
	>
		<Comment>
			<Comment.Content>
				<Rating size="massive" title={`${rating}/5`} rating={rating} maxRating={5} disabled />
				<Comment.Author>{author}</Comment.Author>
				<Comment.Metadata>{date}</Comment.Metadata>
				<Comment.Text>{text}</Comment.Text>
			</Comment.Content>
		</Comment>
	</Comment.Group>
);
const PostDetailsModalContent = (): JSX.Element => {
	const dataStack = useRecoilValue(actionDataStack) as unknown as FlatObject[];

	const [postDetails, setPostDetails] = useState({} as SocialPostDetailsInterface);

	useLayoutEffect(() => {
		const actionData = stackAtomPeek(dataStack);
		if (actionData?.action === 'viewPostDetails') {
			setPostDetails(actionData.data as SocialPostDetailsInterface);
		}
	}, [dataStack]);

	return (
		<Segment basic className="operation-details">
			<ReviewLayout
				author={postDetails.author}
				date={postDetails.post_datetime}
				text={postDetails.text}
				rating={postDetails.rating}
			/>
			<Accordion
				defaultActiveIndex={0}
				panels={[
					{
						key: 'info',
						title: 'Information',
						content: {
							content: (
								<List items={[
									{
										key: 'src',
										header: 'Platform',
										content: postDetails.src && SOCIAL_PLATFORMS[postDetails.src.toUpperCase()].name
									},
									{ key: 'mobile_version', header: 'Mobile App Version', content: postDetails.mobile_version },
									{ key: 'device', header: 'Device', content: postDetails.device }
								]}/>
							),
						},
					},
				]}
			/>
		</Segment>
	);
};
const PostDetailsModalActions = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const setModalStack = useSetRecoilState(actionModalStack);
	const [dataStack, setDataStack] = useRecoilState(actionDataStack);
	const platform = useRecoilValue(postsPlatform);

	const currentUser = useGetUserData();
	const users = useGetUsers();
	const posts = useViewAllPosts();
	const caseDetails = useViewCaseDetails();
	const claim = useClaim();

	const [calledRequest, setCalledRequest] = useState('');
	const [assignToID, setAssignToID] = useState('');

	const reload = async () => {
		loadingModal.addTask('getPosts');

		const response = await posts.request({}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getPostsError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}
		
		loadingModal.removeTask('getPosts');
	};
	const claimPost = async () => {
		loadingModal.addTask('claim');

		setModalStack([]);
		setDataStack([]);
		const data: FlatObject = Object.assign({}, stackAtomPeek(dataStack)?.data);
		const claimResponse = await claim.request({ case_id: data.case_id as string, agent_id: assignToID });

		if (claimResponse.status === OPERATION_REQUEST_STATUS.SUCCESS.id) {
			if (
				currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id &&
				assignToID !== currentUser.get.userId
			) {
				setDataStack([{
					action: 'postAssigned',
					data: null,
					className: 'smaller',
					onClose: reload
				}]);
				setModalStack([{
					content: <Message
						success
						icon="check circle"
						header={OPERATION_REQUEST_STATUS.SUCCESS.name}
						content='Post assigned.'
					/>
				}]);
			}
			else {
				const caseDetailsResponse = await caseDetails.request({ case_id: data.case_id as string });
				if (caseDetailsResponse.status && caseDetailsResponse.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
					setDataStack([{
						action: 'getCaseDetailsError',
						className: 'smaller',
						data: null,
						onClose: reload
					}]);
					setModalStack([{ content: <GenericErrorMessage message={caseDetailsResponse.message}/>
					}]);
				}
				else {
					setDataStack([
						{
							action: 'takeAction',
							data: {
								caseTableData: {
									src: platform,
									title: data.title,
									mobile_version: caseDetailsResponse.mobile_version,
									device: caseDetailsResponse.device,
									status: OPERATION_STATE.CLAIMED.id,
								},
								caseDetails: caseDetailsResponse,
								context: [
									OPERATION_ACTION.REPLY.id,
									OPERATION_ACTION.NO_ACT.id
								],
								reloadFn: reload
							},
							onClose: reload
						}
					]);
					setModalStack([{
						header: 'Take an Action' + (data.src !== 'Google Play Store' && data.title ? `: ${data.title as string}` : ''),
						content: <TakeActionModalContent caseDetails={caseDetailsResponse}/>,
						actions: <TakeActionModalActions/>
					}]);
				}
			}
		}
		else {
			setDataStack([{
				action: 'claimError',
				className: 'smaller',
				data: null,
				onClose: reload
			}]);
			setModalStack([{ content: <GenericErrorMessage message={
				// TODO: Remove "msg" property from this line and the claim response model once fixed
				claimResponse.message ? claimResponse.message: claimResponse.msg
			}/> }]);
		}

		loadingModal.removeTask('claim');
	};

	useEffect(() => {
		if (currentUser.get)
			setAssignToID(currentUser.get.userId);
	}, []);
	useEffect(() => {
		if (calledRequest) {
			switch(calledRequest) {
				case 'claim': claimPost(); break;
			}
			setCalledRequest('');
		}
	}, [calledRequest]);

	return <>
		{
			currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id &&
			<>
				<b>Assign to:</b>
				<Form.Select
					className="operation-cases-user-filter"
					icon="user"
					placeholder="User"
					fluid
					search
					selection
					value={assignToID}
					options={users.get.data ? users.get.data.map(e => ({
						key: e.id,
						value: e.id,
						text: e.name.givenName + ' ' + e.name.familyName
					})): []}
					onChange={(e, val) => setAssignToID(val.value as string)}
				/>
			</>
		}
		<Button key="claim" primary disabled={!assignToID} onClick={() => setCalledRequest('claim')}>
			{(
				currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id &&
				currentUser.get.userId !== assignToID
			) ? 'Assign': 'Claim'}
		</Button>
	</>;
};
const PostDataTable = (): JSX.Element => {
	const platform = useRecoilValue(postsPlatform);
	const [modalStack, setModalStack] = useRecoilState(actionModalStack) as unknown as RecoilAtom<FlatObject[]>;
	const [dataStack, setDataStack] = useRecoilState(actionDataStack) as unknown as RecoilAtom<FlatObject[]>;
	const setFormData = useSetRecoilState(operationReplyForm);
	const [tableColumnLabels, setTableColumnLabels] = useRecoilState(postsColumnLabels);

	const posts = useViewAllPosts();

	const [tableData, setTableData] = useState<SocialPostsDataTableInterface[]>([]);
	const [rowProps, setRowProps] = useState<NodeJS.Dict<unknown>[]>([]);

	const updateTableData = () => {
		setTableData(
			posts.get.data.map(e => Object.assign({}, e)).map(
				e => {
					const status = Object.values(OPERATION_STATE).find(ee => ee.id === e.status);
					return {
						postDate: e.post_datetime.toLocaleString(),
						appVersion: e.mobile_version,
						title: e.title ? <div className="long-text" title={e.title}>{e.title}</div>: '',
						text: <div className="long-text" title={e.text}>{e.text}</div>,
						rating: <Rating title={`${e.rating}/5`} rating={e.rating} maxRating={5} disabled />,
						author: e.author,
						status: status ? status.name : '',
						slaTimeRemaining: getRemainingSLAString(e.sla)
					};
				}
			)
		);
		setRowProps (
			posts.get.data.map(e => {
				if (e.sla > 7200)		// 2 hrs+
					return { className: 'sla-green clickable' };
				else if (e.sla > 3600)	// 30 min+
					return { className: 'sla-yellow clickable' };
				else
					return { className: 'sla-red clickable' };
			})
		);
	};
	const changeTableColumns = () => {
		switch (platform) {
			case 'play_store':
				setTableColumnLabels({
					postDate: 'Date',
					appVersion: 'App Version',
					text: 'Content',
					rating: 'Rating',
					author: 'Author',
					status: 'Status',
					slaTimeRemaining: 'Remaining SLA Time'
				});
				break;
			case 'app_store':
				setTableColumnLabels({
					postDate: 'Date',
					appVersion: 'App Version',
					title: 'Title',
					text: 'Content',
					rating: 'Rating',
					author: 'Author',
					status: 'Status',
					slaTimeRemaining: 'Remaining SLA Time'
				});
				break;
		}
	};
	const viewPostDetails = ({ index }: { index?: number }) => {
		if (typeof index === 'number') {
			const post = Object.assign({}, posts.get).data[index];

			stackAtomPush([dataStack, setDataStack], {
				action: 'viewPostDetails',
				data: post
			});
			stackAtomPush([modalStack, setModalStack], {
				header: 'Review Details' + (post.src !== 'Google Play Store' && post.title ? ': ' + post.title : ''),
				content: <PostDetailsModalContent/>,
				actions: <PostDetailsModalActions/>
			});
		}
	};

	const pageChangeHandler = async ({ pageOffset }: PageArgsInterface) => {
		const response = await posts.request({
			offset_start: pageOffset,
			offset_end: pageOffset + TABLE_PAGELENGTH - 1,
		}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getPostsError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}
	};

	useLayoutEffect(() => { changeTableColumns(); }, [platform]);
	useLayoutEffect(() => { if (posts.get.data) updateTableData(); }, [posts.get]);
	useLayoutEffect(() => { if (!dataStack.length) setFormData({} as SocialReplyFormDataInterface); }, [dataStack]);
	useEffect(() => () => posts.set({} as SocialGetPostsResponseInterface), []);

	return (
		<Segment className="operation-data-table" basic>
			<DataTable
				attached="bottom"
				columns={tableColumnLabels}
				data={tableData as unknown as NodeJS.Dict<unknown>[]}
				rowComponents={(<a/>)}
				rowClick={viewPostDetails}
				rowProps={rowProps}
				pageLength={TABLE_PAGELENGTH}
				totalDataLength={posts.get.total}
				paginationService={pageChangeHandler}
				selectable
			/>
		</Segment>
	)
};

export const ViewPosts = (): JSX.Element => <Tab.Pane as={Container}>
		<PostInfoPopup/>
		<PostSearchForm/>
		<PostResultsInfo/>
		<PostPlatformButtonGroup/>
		<PostDataTable/>
	</Tab.Pane>;