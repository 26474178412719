import { useState } from "react";
import { getAnswerList } from "../../actions/get-list";
import { PredefinedAnswer, PredefinedAnswersGetListRequest } from "../../models/payload-parts";

export type GetAnswerListHook = {
	get: PredefinedAnswer[],
	set: (posts: PredefinedAnswer[]) => void,
	request: (params?: PredefinedAnswersGetListRequest) => Promise<PredefinedAnswer[]>,
};

export const useGetAnswerList = (): GetAnswerListHook => {
	const [answerList, setAnswerList] = useState([] as PredefinedAnswer[]);

	const httpGetAnswerList = async (params?: PredefinedAnswersGetListRequest) => {
		const resp: PredefinedAnswer[] = await getAnswerList(params);

		setAnswerList(resp);
		return resp;
	};

	return {
		get: answerList,
		set: setAnswerList,
		request: httpGetAnswerList,
	};
}