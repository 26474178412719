import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError } from "../../models/http";
import { SocialGetUserDataResponseInterface } from "../models/operation-get-user-data";
import { OPERATION_ROUTE } from "../configs/operation-api";

export const getUserData = async (): Promise<SocialGetUserDataResponseInterface> => {
	const response =
		await axios.get
			<SocialGetUserDataResponseInterface>(
				OPERATION_ROUTE.GET_USER_DATA);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data;
};