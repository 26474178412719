import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Input } from "semantic-ui-react";
import { generateLucene } from "../utils/Helper";

class BlockEmptySearchModal extends React.Component<any> {
  state = { searchQuery: "" };
  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.topic}</h4>
            <p>{this.props.description}</p>
            <Input
              fluid
              focus
              placeholder="put comma to add more than one"
              onChange={(event) => {
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });

                let text = event.target.value;
                text = text.toLowerCase();
                text = text.replace(/ AND /gim, " AND ");
                text = text.replace(/ OR /gim, " OR ");
                this.setState({ searchQuery: text });
              }}
              value={this.state.searchQuery}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={
                this.state.searchQuery.length === 0 ||
                generateLucene(this.state.searchQuery) === "Invalid!!"
              }
              variant={this.props.button1Style}
              onClick={() => {
                this.props.callBack1(this.state.searchQuery);
                this.props.callBack2();
              }}
            >
              {this.props.button1}
            </Button>
            <Button
              variant={this.props.button2Style}
              onClick={this.props.callBack2}
            >
              {this.props.button2}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default BlockEmptySearchModal;
