import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError, WrappedResponse } from "../../models/http";
import { SocialGetCasesRequestInterface, SocialGetCasesResponseInterface } from "../models/operation-get-cases";
import { toDateString } from "../utils/string-formatters";
import { OPERATION_ROUTE } from "../configs/operation-api";

export const getCases = async (payload: SocialGetCasesRequestInterface): Promise<SocialGetCasesResponseInterface> => {
	if (payload.from instanceof Date)
		payload.from = toDateString(payload.from);
	if (payload.to instanceof Date)
		payload.to = toDateString(payload.to);

	const response =
		await axios.post
			<WrappedResponse<SocialGetCasesResponseInterface>>(
				OPERATION_ROUTE.GET_POSTS,
				payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data.responsePayload;
};