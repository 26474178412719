import { useState } from "react";
import { DateRangeInterface } from "../models/date-range";
import { StateHook } from "../models/known-types";

export const useDateRange = (initialVal?: DateRangeInterface): StateHook<DateRangeInterface> => {
	const [dateRange, setDateRange] = useState<DateRangeInterface>(
		initialVal ? initialVal : {
			startDate: new Date(),
			endDate: new Date()
		}
	);

	return [dateRange, setDateRange];
}