import React, {
	useEffect,
	useState
} from "react";
import {
	Accordion,
	Form,
	Header,
	Icon,
	Label
} from "semantic-ui-react";
import {
	secondsToMilliseconds,
	hoursToMilliseconds,
	minutesToMilliseconds,
	differenceInHours
} from "date-fns";
import {
	SocialDateStringFilterOptions,
	SocialEnumFilterOptions,
	SocialFilterDisplayProps,
	SocialNumberFilterOptions,
	SocialStringFilterOptions,
	SocialTimeFilterOptions
} from "../models/filters-sort";
import { useDateRange } from "../hooks/operation-date-range";
import { toDateString } from "../utils/string-formatters";
import { DatePickerButton } from "./building-blocks";
import { POSTS_DEFAULT_REQUEST } from "../configs/operation-api";
import { StateHook } from "../models/known-types";
import { DateRangeInterface } from "../models/date-range";

type NumberRange = { min: number | undefined, max: number | undefined };
type DropdownValueProp = { key: string, value: string, text: string };

const FilterFieldWrapper = (props: React.PropsWithChildren<{
	enabled: StateHook<boolean>,
	exclude: StateHook<boolean>
	changeHandler: () => void
}>): JSX.Element => {
	const [enabled, setEnabled] = props.enabled;
	const [exclude, setExclude] = props.exclude;

	useEffect(props.changeHandler, [enabled, exclude]);

	return <Form.Group className="criteria-field">
		<Form.Checkbox
			className="field-checkbox"
			checked={enabled}
			onChange={(e, target) => setEnabled(target.checked || false)}
			onBlur={props.changeHandler}
		></Form.Checkbox>
		{props.children}
		<Form.Checkbox
			className={'field-checkbox' + (enabled ? '': ' disabled')}
			checked={exclude}
			onChange={(e, target) => setExclude(target.checked || false)}
			onBlur={props.changeHandler}
		></Form.Checkbox>
		<Label className={'exclude-label' + (enabled ? '': ' disabled')}>Exclude</Label>
	</Form.Group>
};

const SearchStringFilterField = ({ id, label, defaultValue = '', onChange }: {
	id: string,
	label: string,
	defaultValue?: string,
	onChange: ({}: SocialStringFilterOptions, enableFilter: boolean) => void
}): JSX.Element => {
	const [match, setMatch] = useState(defaultValue);
	const [enabled, setEnabled] = useState(false);
	const [exclude, setExclude] = useState(false);
	const changeHandler = () => onChange({
		id: id,
		type: 'string',
		match: match,
		exclude: exclude
	}, enabled);

	return <FilterFieldWrapper
		enabled={[enabled, setEnabled]}
		exclude={[exclude, setExclude]}
		changeHandler={changeHandler}
	>
		<Label className={enabled ? '': 'disabled'}>{label}</Label>
		<Form.Input
			className={enabled ? '': 'disabled'}
			value={match}
			onChange={e => setMatch(e.target.value)}
			onBlur={changeHandler}
		/>
	</FilterFieldWrapper>;
};

// TODO
const SearchDateFilterField = ({ id, label, externalHook, onChange }: {
	id: string,
	label: string,
	defaultValues?: { min: string, max: string },
	externalHook?: StateHook<DateRangeInterface>,
	onChange: ({}: SocialDateStringFilterOptions, enableFilter: boolean) => void
}): JSX.Element => {
	const [dateRange, setDateRange] = externalHook ?? useDateRange({
		startDate: POSTS_DEFAULT_REQUEST.from as Date,
		endDate: POSTS_DEFAULT_REQUEST.to as Date
	});
	const [enabled, setEnabled] = useState(false);
	const [exclude, setExclude] = useState(false);
	const changeHandler = () => {
		onChange({
			id: id,
			type: 'date',
			min: toDateString(dateRange.startDate),
			max: toDateString(dateRange.endDate),
			exclude: exclude
		}, enabled);
	};

	return <FilterFieldWrapper
		enabled={[enabled, setEnabled]}
		exclude={[exclude, setExclude]}
		changeHandler={changeHandler}
	>
		<Label className={enabled ? '': 'disabled'}>{label}</Label>
		<DatePickerButton
			inForm
			className={enabled ? '': 'disabled'}
			defaultRange={{
				startDate: POSTS_DEFAULT_REQUEST.from as Date,
				endDate: POSTS_DEFAULT_REQUEST.to as Date
			}}
			range={[dateRange, setDateRange]}
			onConfirm={changeHandler}
			confirmButtonContent={<><Icon name="checkmark"/>Set</>}
		>
			Pick Date Range
		</DatePickerButton>
	</FilterFieldWrapper>;
};
const SearchTimeFilterField = ({
	id,
	label,
	limit = { min: 0, max: undefined },
	defaultValues = { min: 0, max: 0 },
	units = ['hours', 'minutes', 'seconds'],
	onChange
}: {
	id: string,
	label: string,
	limit?: { min: number | undefined, max: number | undefined },
	defaultValues?: { min: number, max: number },
	units?: ('hours' | 'minutes' | 'seconds')[],
	onChange: ({}: SocialTimeFilterOptions, enableFilter: boolean) => void
}): JSX.Element => {
	const [minTime, setMinTime] = useState(new Date(defaultValues.min));
	const [minHours, setMinHours] = useState(differenceInHours(minTime, 0));
	const [minMinutes, setMinMinutes] = useState(minTime.getUTCMinutes());
	const [minSeconds, setMinSeconds] = useState(minTime.getUTCSeconds());

	const [maxTime, setMaxTime] = useState(new Date(defaultValues.max));
	const [maxHours, setMaxHours] = useState(differenceInHours(maxTime, 0));
	const [maxMinutes, setMaxMinutes] = useState(maxTime.getUTCMinutes());
	const [maxSeconds, setMaxSeconds] = useState(maxTime.getUTCSeconds());
	
	const [minTimeEnabled, setMinTimeEnabled] = useState(true);
	const [maxTimeEnabled, setMaxTimeEnabled] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [exclude, setExclude] = useState(false);
	const getProcessedDate = (hours: number, minutes: number, seconds: number) => new Date(hoursToMilliseconds(hours) + minutesToMilliseconds(minutes) + secondsToMilliseconds(seconds));
	const clampTime = (time: Date) => {
		let ms = time.getTime();
		if (limit.min !== undefined) {
			ms = Math.max(ms, limit.min * 1000);
		}
		if (limit.max !== undefined) {
			ms = Math.min(ms, limit.max * 1000);
		}
		return new Date(ms);
	};
	const changeHandler = (params: {
		min: Date | undefined,
		max: Date | undefined,
		exclude: boolean,
		enabled: boolean
	}) => onChange({
		id: id,
		type: 'time',
		min: (minTimeEnabled && params.min) ? params.min.getTime() / 1000: undefined,
		max: (maxTimeEnabled && params.max) ? params.max.getTime() / 1000: undefined,
		exclude: params.exclude
	}, params.enabled);
	const updateTimeStates = (min?: Date, max?: Date) => {
		if (min !== undefined) {
			const clamped = clampTime(min);
			setMinTime(clamped);
			setMinHours(differenceInHours(clamped, 0));
			setMinMinutes(clamped.getUTCMinutes());
			setMinSeconds(clamped.getUTCSeconds());
		}
		if (max !== undefined) {
			const clamped = clampTime(max);
			setMaxTime(clamped);
			setMaxHours(differenceInHours(clamped, 0));
			setMaxMinutes(clamped.getUTCMinutes());
			setMaxSeconds(clamped.getUTCSeconds());
		}
	}
	const getMinMaxTimeProperties = (params: {
		field?: 'Maximum' | 'Minimum',
		val?: Date,
		preStateFlag?: boolean
	} = {}) => {
		const properties = {
			min: maxTime,
			max: minTime,
			exclude: exclude,
			enabled: enabled
		};
		let swap = false;
		if (params.val) {
			if (properties.min !== undefined &&
				params.field === 'Minimum' &&
				params.val > properties.min
			) {
				swap = true;
				properties.max = params.val;
			}
			else if (properties.max !== undefined &&
				params.field === 'Maximum' &&
				params.val < properties.max
			) {
				swap = true;
				properties.min = params.val;
			}
		}
		else if (properties.min !== undefined &&
			properties.max !== undefined &&
			properties.min < properties.max
		) {
			swap = true;
		}
		swap = (
			swap &&
			(minTimeEnabled || (params.field === 'Minimum' && params.preStateFlag)) &&
			(maxTimeEnabled || (params.field === 'Maximum' && params.preStateFlag))
		) as boolean;
		if (!swap) {
			const temp = properties.max;
			properties.max = properties.min;
			properties.min = temp;
			if (params.val) {
				if (params.field === 'Maximum') {
					properties.max = params.val;
				}	
				else {
					properties.min = params.val;
				}
			}
		}
		return properties;
	};
	const minMaxTimeInputOnBlurHandler = (value: number, title: 'Minimum' | 'Maximum', unit: string) => {
		let refTime: Date = new Date(0);
		let newTime: Date | undefined = undefined;
		switch (title) {
			case 'Minimum': refTime = minTime; break;
			case 'Maximum': refTime = maxTime; break;
		}
		switch (unit) {
			case 'hours': newTime = getProcessedDate(value, refTime.getUTCMinutes(), refTime.getUTCSeconds()); break;
			case 'minutes': newTime = getProcessedDate(differenceInHours(refTime, 0), value, refTime.getUTCSeconds()); break;
			case 'seconds': newTime = getProcessedDate(differenceInHours(refTime, 0), refTime.getUTCMinutes(), value); break;
		}
		const properties = getMinMaxTimeProperties({
			field: title,
			val: newTime
		});

		updateTimeStates(properties.min, properties.max);
		changeHandler(properties);
	};

	const timeInputs = (title: 'Minimum' | 'Maximum') => units.map((unit: string, i: number) => <React.Fragment key={i}>
		{(() => {
			switch (`${title}|${i}`) {
			case 'Minimum|0':
				return <Form.Checkbox
					className={'field-checkbox' + ((enabled && maxTimeEnabled) ? '': ' disabled')}
					checked={minTimeEnabled}
					onChange={(e, target) => {
						const checked = target.checked || false;
						setMinTimeEnabled(checked);
						const properties = getMinMaxTimeProperties({
							field: title,
							preStateFlag: true
						});
						updateTimeStates(properties.min, properties.max);
					}}
					onBlur={() => changeHandler({
						min: minTime,
						max: maxTime,
						exclude: exclude,
						enabled: enabled
					})}
				></Form.Checkbox>;
			case 'Maximum|0':
				return <Form.Checkbox
					className={'field-checkbox' + ((enabled && minTimeEnabled) ? '': ' disabled')}
					checked={maxTimeEnabled}
					onChange={(e, target) => {
						const checked = target.checked || false;
						setMaxTimeEnabled(checked);
							const properties = getMinMaxTimeProperties({
								field: title,
								preStateFlag: true
							});
							updateTimeStates(properties.min, properties.max);
					}}
					onBlur={() => changeHandler({
						min: minTime,
						max: maxTime,
						exclude: exclude,
						enabled: enabled
					})}
				></Form.Checkbox>;
			default: return '';
			}
		})()}
		{(() => {
			switch (title) {
				case 'Minimum':
					return <Label className={(i > 0 ? '': 'time-input-title ') + ((enabled && minTimeEnabled) ? '': 'disabled')}>
						{i > 0 ? ':': title}
					</Label>;
				case 'Maximum':
					return <Label className={(i > 0 ? '': 'time-input-title ') + ((enabled && maxTimeEnabled) ? '': 'disabled')}>
						{i > 0 ? ':': title}
					</Label>;
			}
		})()}
		<Form.Input
			fluid
			type="number"
			className={(() => {
				switch (title) {
					case 'Minimum': return 'time ' + unit + ((enabled && minTimeEnabled) ? '': ' disabled');
					case 'Maximum': return 'time ' + unit + ((enabled && maxTimeEnabled) ? '': ' disabled');
				}
			})()}
			value={(() => {
				switch (title + '|' + unit) {
					case 'Minimum|hours'  : return minHours;
					case 'Minimum|minutes': return minMinutes;
					case 'Minimum|seconds': return minSeconds;
					case 'Maximum|hours'  : return maxHours;
					case 'Maximum|minutes': return maxMinutes;
					case 'Maximum|seconds': return maxSeconds;
				}
			})()}
			min={0}
			onChange={e => {
				const value = parseFloat(e.target.value);
				switch (title + '|' + unit) {
					case 'Minimum|hours'  : setMinHours(isNaN(value) ? 0: value); break;
					case 'Minimum|minutes': setMinMinutes(isNaN(value) ? 0: value); break;
					case 'Minimum|seconds': setMinSeconds(isNaN(value) ? 0: value); break;
					case 'Maximum|hours'  : setMaxHours(isNaN(value) ? 0: value); break;
					case 'Maximum|minutes': setMaxMinutes(isNaN(value) ? 0: value); break;
					case 'Maximum|seconds': setMaxSeconds(isNaN(value) ? 0: value); break;
				}
				e.target.value = value.toString();
			}}
			onBlur={(e: React.FocusEvent<HTMLInputElement>) => minMaxTimeInputOnBlurHandler(
				parseInt(e.target.value), title, unit
			)}
		/>
	</React.Fragment>);

	return <FilterFieldWrapper
		enabled={[enabled, setEnabled]}
		exclude={[exclude, setExclude]}
		changeHandler={() => changeHandler({
			min: minTime,
			max: maxTime,
			exclude: exclude,
			enabled: enabled
		})}
	>
		<Label className={enabled ? '': 'disabled'}>{label}</Label>
		{timeInputs('Minimum')}
		{timeInputs('Maximum')}
	</FilterFieldWrapper>;
};
const SearchNumberFilterField = ({ id, label, limit, defaultValues = { min: 0, max: 0 }, onChange }: {
	id: string,
	label: string,
	limit?: { min: number | undefined, max: number | undefined },
	defaultValues?: { min: number | undefined, max: number | undefined },
	onChange: ({}: SocialNumberFilterOptions, enableFilter: boolean) => void
}): JSX.Element => {
	const [min, setMin] = useState(defaultValues.min);
	const [max, setMax] = useState(defaultValues.max);
	const [minEnabled, setMinEnabled] = useState(true);
	const [maxEnabled, setMaxEnabled] = useState(false);
	const [enabled, setEnabled] = useState(false);
	const [exclude, setExclude] = useState(false);
	const changeHandler = (params: {
		min: number | undefined,
		max: number | undefined,
		exclude: boolean,
		enabled: boolean
	}) => onChange({
		id: id,
		type: 'number',
		min: minEnabled ? params.min: undefined,
		max: maxEnabled ? params.max: undefined,
		exclude: params.exclude
	}, params.enabled);
	const update = () => changeHandler({
		min: min,
		max: max,
		exclude: exclude,
		enabled: enabled
	});
	const swapMinMax = (field?: 'Maximum' | 'Minimum', val?: number) => {
		const properties = {
			min: max,
			max: min,
			exclude: exclude,
			enabled: enabled
		};
		let swap = false;
		if (val) {
			if (properties.min !== undefined &&
				field === 'Minimum' &&
				val > properties.min
			) {
				swap = true;
				properties.max = val;
			}
			else if (properties.max !== undefined &&
				field === 'Maximum' &&
				val < properties.max
			) {
				swap = true;
				properties.min = val;
			}
		}
		else if (properties.min !== undefined &&
			properties.max !== undefined &&
			properties.min < properties.max
		) {
			swap = true;
		}
		if (swap) {
			setMin(properties.min === undefined ? 0: properties.min);
			setMax(properties.max === undefined ? 0: properties.max);
			changeHandler(properties);
		}
		else {
			update();
		}
	};

	return <FilterFieldWrapper
		enabled={[enabled, setEnabled]}
		exclude={[exclude, setExclude]}
		changeHandler={update}
	>
		<Label className={enabled ? '': 'disabled'}>{label}</Label>
		<Form.Checkbox
			className={'field-checkbox' + ((enabled && maxEnabled) ? '': ' disabled')}
			checked={minEnabled}
			onChange={(e, target) => {
				const checked = target.checked || false;
				setMinEnabled(checked);
				if (checked && maxEnabled) swapMinMax();
			}}
			onBlur={update}
		></Form.Checkbox>
		<Form.Input
			className={(enabled && minEnabled) ? '': 'disabled'}
			label='Minimum'
			fluid
			type='number'
			value={min}
			min={limit?.min}
			max={limit?.max}
			onChange={e => {
				const value = parseFloat(e.target.value);
				setMin(isNaN(value) ? 0: value);
				e.target.value = value.toString();
			}}
			onBlur={(minEnabled && maxEnabled) ?
				(e: React.FocusEvent<HTMLInputElement>) => swapMinMax('Minimum', parseFloat(e.target.value)) :
				update
			}
		/>
		<Form.Checkbox
			className={'field-checkbox' + ((enabled && minEnabled) ? '': ' disabled')}
			checked={maxEnabled}
			onChange={(e, target) => {
				const checked = target.checked || false;
				setMaxEnabled(checked);
				if (checked && minEnabled) swapMinMax();
			}}
			onBlur={update}
		></Form.Checkbox>
		<Form.Input
			className={(enabled && maxEnabled) ? '': 'disabled'}
			label='Maximum'
			fluid
			type='number'
			value={max}
			min={limit?.min}
			max={limit?.max}
			onChange={e => {
				const value = parseFloat(e.target.value);
				setMax(isNaN(value) ? 0: value);
				e.target.value = value.toString();
			}}
			onBlur={(minEnabled && maxEnabled) ?
				(e: React.FocusEvent<HTMLInputElement>) => swapMinMax('Maximum', parseFloat(e.target.value)) :
				update
			}
		/>
	</FilterFieldWrapper>;
};
const SearchEnumFilterField = ({ id, label, defaultValues = [], allValues, onChange }: {
	id: string,
	label: string,
	defaultValues?: string[],
	allValues: DropdownValueProp[],
	onChange: ({}: SocialEnumFilterOptions, enableFilter: boolean) => void
}): JSX.Element => {
	const [values, setValues] = useState<string[]>(defaultValues);
	const [enabled, setEnabled] = useState(false);
	const [exclude, setExclude] = useState(false);
	const changeHandler = () => onChange({
		id: id,
		type: 'enum',
		values: values,
		exclude: exclude
	}, enabled);

	useEffect(changeHandler, [values, exclude, enabled]);

	return <FilterFieldWrapper
		enabled={[enabled, setEnabled]}
		exclude={[exclude, setExclude]}
		changeHandler={changeHandler}
	>
		<Label className={enabled ? '': 'disabled'}>{label}</Label>
		<Form.Select
			className={enabled ? '': 'disabled'}
			icon="filter"
			search
			multiple
			selection
			value={values}
			options={allValues}
			onChange={(e, val) => setValues(val.value as string[])}
			// onBlur={changeHandler}
		/>
	</FilterFieldWrapper>
};

export const SearchCriteriasAccordion = ({ valueTypes, onSortingChange }: {
	valueTypes: SocialFilterDisplayProps[],
	onSortingChange: ({ id, order }: { id: string, order: 'asc' | 'desc' }) => void,
}): JSX.Element => {
	const [sortColumn, setSortColumn] = useState('');
	const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
	
	useEffect(() => onSortingChange({ id: sortColumn, order: sortDirection }), [sortColumn, sortDirection]);

	return <Accordion
		fluid
		className="criteria-accordion"
		panels={[{
			key: '',
			title: {
				as: Header,
				size: 'small',
				content: 'Advance Filter & Sorting Options'
			},
			content: {
				content: <Form>
					<Header size="small">
						<Icon name="filter"/>
						<Header.Content>Filters</Header.Content>
					</Header>
					{valueTypes.map((e, i) => {
						switch (e.type) {
							case 'string':
								return <SearchStringFilterField
									id={e.id}
									key={i}
									label={e.label}
									onChange={e.onChange}
								/>;
							case 'date':
								return <SearchDateFilterField
									id={e.id}
									key={i}
									label={e.label}
									onChange={e.onChange}
									externalHook={e.externalHook as StateHook<DateRangeInterface>}
								/>;
							case 'time':
								return <SearchTimeFilterField
									id={e.id}
									key={i}
									label={e.label}
									limit={e.limit as NumberRange}
									onChange={e.onChange}
								/>;
							case 'number':
								return <SearchNumberFilterField
									id={e.id}
									key={i}
									label={e.label}
									limit={e.limit as NumberRange}
									onChange={e.onChange}
								/>;
							case 'enum':
								return <SearchEnumFilterField
									id={e.id}
									key={i}
									label={e.label}
									allValues={e.values as DropdownValueProp[]} onChange={e.onChange}
								/>;
						}
					})}
					<Header size="small">
						<Icon name="sort amount up"/>
						<Header.Content>Sort</Header.Content>
					</Header>
					<Form.Group>
						<Form.Select
							label="By Column..."
							value={sortColumn}
							clearable
							options={valueTypes.map(e => ({
								key: e.id,
								value: e.id,
								text: e.label
							}))}
							placeholder="(None)"
							onChange={(e, target) => {
								setSortColumn(target.value as string);
								onSortingChange({ id: target.value as string, order: sortDirection });
							}}
						/>
						<Form.Select
							label="Order Direction"
							disabled={sortColumn === ''}
							value={sortDirection}
							options={[
								{
									key: 'asc',
									value: 'asc',
									text: 'Ascending',
									icon: 'sort alphabet down'
								},
								{
									key: 'desc',
									value: 'desc',
									text: 'Descending',
									icon: 'sort alphabet up'
								}
							]}
							onChange={(e, target) => {
								setSortDirection(target.value as 'asc' | 'desc');
								onSortingChange({ id: target.value as 'asc' | 'desc', order: sortDirection })
							}}
						/>
					</Form.Group>
				</Form>
			},
		}]}
	/>;
};