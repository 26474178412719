import React from "react";
import { Modal } from "react-bootstrap";
import { Button, Icon } from "semantic-ui-react";

class Login extends React.Component<any> {
  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Welcome to InnoHub Social Monitoring Tools
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <img
              src="/image/1909737.jpg"
              alt="InnoHub"
              style={{
                width: "100%",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            />
            <h4 style={{ marginTop: "15px" }}>Please sign in before using</h4>
            <p>
              In case you don't have the access please contact Innovation
              department.
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button fluid color="google plus" href="/auth/google">
              <Icon name="google" /> Sign in with Google
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default Login;
