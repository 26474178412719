import React, { useEffect, useLayoutEffect, useState } from "react";

import '../css/operation.css';

import {
	Accordion,
	Button,
	Comment,
	Container,
	Form,
	Header,
	Icon,
	Label,
	List,
	ListItemProps,
	Message,
	Popup,
	Rating,
	Segment,
	SemanticShorthandCollection,
	Tab
} from "semantic-ui-react";
import {
	DataTable,
	DatePickerButton,
	GenericErrorMessage,
	RatingRangeSelect,
	ReloadPageButton
} from "./building-blocks";
import { SearchCriteriasAccordion } from "./search-criterias";

import {
	useRecoilState,
	useRecoilValue,
	useSetRecoilState
} from "recoil";
import {
	operationActionModalStack as actionModalStack,
	operationActionDataStack as actionDataStack,
	operationCasesPlatform as casesPlatform,
	operationCasesColumnLabels as casesColumnLabels,
	operationReplyForm,
	operationGetCasesRequest
} from "../recoil-atoms/operation";
import { SocialCasesDataTableInterface, SocialCaseDetailsInterface, SocialGetCasesResponseInterface } from "../models/operation-get-cases";
import { useViewCases } from "../hooks/services/operation-get-cases";
import { useDateRange } from "../hooks/operation-date-range";
import { stackAtomPeek, stackAtomPush } from "../utils/recoil-helpers";
import {
	CASES_DEFAULT_REQUEST,
	CASES_VALUE_TYPES,
	OPERATION_ACTION,
	OPERATION_REQUEST_STATUS,
	OPERATION_STATE
} from "../configs/operation-api";
import {
	SOCIAL_PLATFORMS,
	TABLE_PAGELENGTH,
	OPERATION_USER_ROLE,
	CASES_STATUS_FILTER,
	CASES_CATEGORY,
	CASES_ISSUE_TYPE,
	CASES_SENTIMENT,
	QUICK_PREDEF_ANSWER_COUNT
} from "../configs/operation-ui";
import { PageArgsInterface } from "../models/data-table";
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { useViewCaseDetails } from "../hooks/services/operation-get-case-details";
import { useSubmitAction } from "../hooks/services/operation-submit-action";
import { SocialGetCaseDetailsResponseInterface } from "../models/operation-get-case-details";
import { SocialReplyFormDataInterface } from "../models/operation-submit-action";
import { useGetUsers } from "../hooks/services/operation-get-users";
import { useGetUserData } from "../hooks/services/operation-get-user-data";
import { useStatusFilter } from "../hooks/operation-status-filter";
import { getRemainingSLAString } from "../utils/string-formatters";
import {
	SocialStringFilterOptions,
	SocialDateStringFilterOptions,
	SocialTimeFilterOptions,
	SocialNumberFilterOptions,
	SocialEnumFilterOptions,
	SocialSearchCriterias
} from "../models/filters-sort";
import { FlatObject, NestedObject, RecoilAtom } from "../models/known-types";
import { useGetAnswerList } from "../../social-predef-answers/hooks/services/get-list";
import { PredefinedAnswer } from "../../social-predef-answers/models/payload-parts";

const CasesAdvancedSearchPanel = (): JSX.Element => {
	const [currentPayload, setCurrentPayload] = useRecoilState(operationGetCasesRequest);
	const tableColumnLabels = useRecoilValue(casesColumnLabels);
	const [criterias, setCriterias] = useState<SocialSearchCriterias>();

	const getStatusIDs = (values: string[]) => values.reduce((acc, cur) => {
		const status = CASES_STATUS_FILTER.find(e => e.key === cur);
		return status ? acc.concat(status.value): acc;
	}, [] as string[]);
	const filterChangeHandler = (
		newFilter :
			SocialStringFilterOptions |
			SocialDateStringFilterOptions |
			SocialTimeFilterOptions |
			SocialNumberFilterOptions |
			SocialEnumFilterOptions,
		enableFilter: boolean
	) => {
		const ft = {...newFilter, id: CASES_VALUE_TYPES.find(e => e.id === newFilter.id)?.alt_id as string };
		const index = criterias?.filters?.findIndex(e => e.id === ft.id) ?? -1;

		if (enableFilter) {
			if (index === -1) {
				setCriterias(Object.assign(
					{},
					criterias,
					{ filters: criterias?.filters?.concat(ft) ?? [ft] }
				));
			}
			else if (criterias && criterias.filters?.length) {
				const newFilters = [ ...criterias.filters ];
				if (ft.id === 'status') {
					(ft as SocialEnumFilterOptions).values = getStatusIDs((ft as SocialEnumFilterOptions).values);
				}
				newFilters[index] = ft;
				const newCriterias = { ...criterias, filters: newFilters };
				setCriterias(newCriterias);
				setCurrentPayload({ ...currentPayload, criterias: newCriterias });
			}
		}
		else if (criterias && criterias.filters?.length && index > -1) {
			const newFilters = [ ...criterias.filters ];
			newFilters.splice(index, 1);
			const newCriterias = { ...criterias, filters: newFilters };
			setCriterias(newCriterias);
			setCurrentPayload({ ...currentPayload, criterias: newCriterias });
		}
	}

	return <SearchCriteriasAccordion
		valueTypes={CASES_VALUE_TYPES
			.filter(e => tableColumnLabels[e.id])
			.map(e => {
				return Object.assign(e, {
					label: tableColumnLabels[e.id],
					onChange: filterChangeHandler
				});
			})
		}
		onSortingChange={(sort) => {
			const match = CASES_VALUE_TYPES.find(e => e.id === sort.id)?.alt_id;
			sort.id = match ? match: '';
			const newCriterias = Object.assign({}, criterias, { sort: sort });
			setCriterias(newCriterias);
			setCurrentPayload({ ...currentPayload, criterias: newCriterias });
		}}
	/>;
};
const CaseSearchForm = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [dateRange, setDateRange] = useDateRange({
		startDate: CASES_DEFAULT_REQUEST.from as Date,
		endDate: CASES_DEFAULT_REQUEST.to as Date
	});
	const statusFilter = useStatusFilter();
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);

	const currentUser = useGetUserData();
	const cases = useViewCases();
	const users = useGetUsers();

	const [query, setQuery] = useState('');
	const [authorFilter, setAuthorFilter] = useState('');
	const [userFilter, setUserFilter] = useState('');
	const [initialized, setInitialized] = useState(false);
	const [minRating, setMinRating] = useState(CASES_DEFAULT_REQUEST.filter ? CASES_DEFAULT_REQUEST.filter.min_rating : 0);
	const [maxRating, setMaxRating] = useState(CASES_DEFAULT_REQUEST.filter ? CASES_DEFAULT_REQUEST.filter.max_rating : 5);

	const applyCaseFilters = async () => {
		loadingModal.addTask('getCases');

		const payload: NodeJS.Dict<unknown> = {
			q: query,
			author: authorFilter ? authorFilter: '',
			from: dateRange.startDate,
			to: dateRange.endDate,
			offset_start: CASES_DEFAULT_REQUEST.offset_start,
			offset_end: CASES_DEFAULT_REQUEST.offset_end,
			agent_id: currentUser.get.userId,
			filter: {
				agent_id: '',
				status: [],
				min_rating: minRating,
				max_rating: maxRating
			}
		};
		if (!cases.get.total && !initialized) {
			setInitialized(true);
			const isSupervisor = currentUser.get.role && currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id;
			payload.source = CASES_DEFAULT_REQUEST.source;
			if (isSupervisor) {
				statusFilter.set(['awaiting_approval']);
				payload.filter = CASES_DEFAULT_REQUEST.filter
			}
			if (userFilter) {
				const newFilter = { agent_id: userFilter };
				payload.filter = payload.filter ? Object.assign(payload.filter, newFilter) : newFilter;
			}
		}
		else {
			payload.filter = {
				status: ([] as string[]).concat.apply(
					[] as string[][],
					statusFilter.get.map(e => e.value as string[])
				),
				agent_id: userFilter,
				min_rating: minRating,
				max_rating: maxRating
			}
		}
		const response = await cases.request(payload, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getCasesError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}

		loadingModal.removeTask('getCases');
	};

	return (
		<Segment secondary>
			<Header as="h2">Search Cases</Header>
			<Form className="operation-search-form">
				<Form.Group>
					<Form.Input
						icon="search"
						fluid
						label="Query"
						onChange={e => setQuery(e.target.value)}
					/>
					<Form.Input
						icon="user circle"
						className="author-filter"
						fluid
						label="Filter by Author"
						onChange={e => setAuthorFilter(e.target.value)}
					/>
					<Form.Field className="operation-date-label">
						<Label pointing="right">
							{'From: ' + dateRange.startDate.toLocaleDateString()}<br/>
							{'To: ' + dateRange.endDate.toLocaleDateString()}
						</Label>
					</Form.Field>
					<DatePickerButton
						inForm
						defaultRange={{
							startDate: CASES_DEFAULT_REQUEST.from as Date,
							endDate: CASES_DEFAULT_REQUEST.to as Date
						}}
						range={[dateRange, setDateRange]}
						onConfirm={applyCaseFilters}
						confirmButtonContent={<><Icon name="search"/>Set & Search</>}
					>
						Date Range
					</DatePickerButton>
					<Form.Button
						className="search-button"
						color="green"
						icon="search"
						content="Search"
						onClick={() => applyCaseFilters()}
					/>
				</Form.Group>
				<Form.Group className="operation-cases-filters">
					<Form.Select
						className={
							(currentUser.get.role && currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id) ?
								'operation-cases-status-filter':
								'operation-cases-status-filter agent'
						}
						icon="filter"
						label="Filter by Status"
						fluid
						search
						multiple
						selection
						value={statusFilter.get.map(e => e.key)}
						options={
							CASES_STATUS_FILTER
								.filter(e => e.filter)
								.map(e => ({
									key: e.key,
									value: e.key,
									text: e.text
								}))
						}
						onChange={(e, val) => statusFilter.set(val.value as string[])}
					/>
					<RatingRangeSelect
						label="Filter by Rating"
						min={minRating}
						max={maxRating}
						onChange={(min, max) => {
							setMinRating(min);
							setMaxRating(max);
						}}
					/>
					{
						(currentUser.get.role && currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id) &&
						<Form.Select
							className="operation-cases-user-filter"
							icon="user"
							label="Filter by User"
							fluid
							search
							selection
							clearable
							value={userFilter}
							options={users.get.data ? users.get.data.map(e => ({
								key: e.id,
								value: e.id,
								text: e.name.givenName + ' ' + e.name.familyName
							})): []}
							onChange={(e, val) => setUserFilter(val.value as string)}
						/>
					}
				</Form.Group>
			</Form>
			<CasesAdvancedSearchPanel/>
		</Segment>
	);
};
const CaseResultsInfo = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);
	
	const cases = useViewCases();

	const reload = async () => {
		loadingModal.addTask('getCases');

		const response = await cases.request({}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getCasesError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}

		loadingModal.removeTask('getCases');
	};

	return (
		<Segment basic className="operation-results-info">
			<b>Total Results: </b>
			<Label>{cases.get.total}</Label>
			<Button basic circular floated="right" icon="refresh" onClick={reload}/>
		</Segment>
	);
};
const CasePlatformButtonGroup = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [platform, setPlatform] = useRecoilState(casesPlatform);
	const setModalStack = useSetRecoilState(actionModalStack);
	const setDataStack = useSetRecoilState(actionDataStack);
	
	const cases = useViewCases();

	const setPlatformButtonActive = (id: string) => platform == id ? 'active ' + id : '';
	const onPlatformChange = async (id: string) => {
		if (platform !== id) {
			loadingModal.addTask('getCases');

			setPlatform(id);
			const response = await cases.request({
				source: id,
				offset_start: CASES_DEFAULT_REQUEST.offset_start,
				offset_end: CASES_DEFAULT_REQUEST.offset_end
			}, true);
			if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
				setDataStack([{
					action: 'getCasesError',
					className: 'smaller',
					data: null,
					locked: true
				}]);
				setModalStack([{
					content: <GenericErrorMessage message={response.message}/>,
					actions: <ReloadPageButton/>
				}]);
			}
			
			loadingModal.removeTask('getCases');
		}
	};

	useEffect(() => setPlatform(Object.values(SOCIAL_PLATFORMS)[0].id), []);

	return (
		<Button.Group className='operation-platform-buttons' attached='top' color='grey' labeled icon>
			{Object.values(SOCIAL_PLATFORMS).map((p, i) =>
				<Button
					key={i}
					className={setPlatformButtonActive(p.id)}
					onClick={() => onPlatformChange(p.id)}
					icon={p.icon}
					content={p.name}
				/>
			)}
		</Button.Group>
	);
};
const ReviewLayout = ({author, date, text, rating, ...props}: {
	author: string,
	date: string,
	text: string,
	rating: string | number,
	[key: string]: unknown
}): JSX.Element => (
	<Comment.Group
		as={Segment}
		fluid="true"
		className="operation-review-layout"
		size="large"
		{...props}
	>
		<Comment>
			<Comment.Content>
				<Rating size="massive" title={`${rating}/5`} rating={rating} maxRating={5} disabled />
				<Comment.Author>{author}</Comment.Author>
				<Comment.Metadata>{date}</Comment.Metadata>
				<Comment.Text>{text}</Comment.Text>
			</Comment.Content>
		</Comment>
	</Comment.Group>
);
const CaseInfoLayout = ({caseTableData}: {
	caseTableData: SocialCaseDetailsInterface,
	[key: string]: unknown
}): JSX.Element => (
	<Accordion defaultActiveIndex={0} panels={[
		{
			key: 'info',
			title: 'Information',
			content: {
				content: (
					<List
						items={(
							() => [
								{
									key: 'src',
									header: 'Platform',
									content: caseTableData.src && SOCIAL_PLATFORMS[caseTableData.src.toUpperCase()].name
								},
								{ key: 'mobile_version', header: 'Mobile App Version', content: caseTableData.mobile_version },
								{ key: 'device', header: 'Device', content: caseTableData.device },
								{
									key: 'status',
									header: 'Status',
									content: (() => {
										if (caseTableData.status) {
											const statusMapItem = CASES_STATUS_FILTER.find(e => e.value.includes(caseTableData.status));
											return statusMapItem ?
												<span
													title={Object.values(OPERATION_STATE).find(e => e.id === caseTableData.status)?.title}
												>
													{statusMapItem.text}
												</span> :
												''
											;
										}
										return '';
									})()
								}
							]
						)() as SemanticShorthandCollection<ListItemProps>}
					/>
				)
			}
		}
	]}/>
);
const CaseHistoryLayout = ({caseDetails}: {
	caseDetails: SocialGetCaseDetailsResponseInterface,
	[key: string]: unknown
}): JSX.Element => {
	const users = useGetUsers();

	return caseDetails.history && caseDetails.history.length ?
		<Accordion
			panels={[
				{
					key: 'history',
					title: 'History',
					content: {
						content: (
							<Comment.Group
								as={Segment}
								fluid="true"
								size="large"
							>
								{caseDetails.history && caseDetails.history.map((e, i) =>
									<Comment
										fluid="true"
										key={i}
										className={
											e.type === 'user' ?
												'operation-reply-history-comment' :
												'operation-reply-history-comment agent-reply'
										}
									>
										{i && e.type === 'user' ? <hr></hr> : ''}
										<Comment.Content>
											{e.rating && <Rating size="massive" title={e.rating + '/5'} rating={e.rating} maxRating={5} disabled />}
											<Comment.Author>
												{(() => {
													if (e.type === 'user')
														return caseDetails.author;

													const agent = users.get.data.find(ee => ee.id === caseDetails.agent_id);
													return agent ?
														agent.name.givenName + ' ' + agent.name.familyName:
														'An Agent'
													;
												})()}
											</Comment.Author>
											<Comment.Metadata>{e.date}</Comment.Metadata>
											<Comment.Text>{e.text}</Comment.Text>
										</Comment.Content>
									</Comment>
								)}
							</Comment.Group>
						),
					},
				},
			]}
		/>: <></>
	;
};
const CaseReplyViewLayout = ({formValues}: { formValues: Partial<SocialReplyFormDataInterface> }): JSX.Element => (
	<Segment className="operation-reply-form">
		<Header as="h2">Response</Header>
		{(() => {
			const title = Object.values(OPERATION_STATE).find(e => formValues.status === e.id)?.title.toLowerCase();
			if (title && new RegExp(/rejected/).exec(title))
				return <Message
					error
					icon="warning"
					header="Rejected"
					content={formValues.rejectMsg ? formValues.rejectMsg: ''}
				/>;
			if (title && new RegExp(/action needed/).exec(title))
				return <Message
					error
					icon="warning"
					header="Action Needed"
					content={formValues.rejectMsg ? formValues.rejectMsg: ''}
				/>;
		})()}
		<Form reply>
			<Form.Group inline widths="equal">
				<Form.Select
					fluid
					disabled
					value={formValues.issueType ? formValues.issueType : 0}
					label='Category Level 1'
					options={Object.values(CASES_ISSUE_TYPE)}
				/>
				<Form.Select
					fluid
					disabled
					value={formValues.category ? formValues.category : 0}
					label='Category Level 2'
					options={Object.values(CASES_CATEGORY)}
				/>
				<Form.Select
					fluid
					disabled
					value={formValues.sentimentType ? formValues.sentimentType : 0}
					label='Sentiment'
					options={Object.values(CASES_SENTIMENT)}
					className={
						Object.values(CASES_SENTIMENT)
							.find(e => e.value === formValues.sentimentType)
							?.className || ''
					}
				/>
			</Form.Group>
			<Form.TextArea
				disabled
				defaultValue={formValues.text}
				label='Response Text'
				rows={5}
			/>
			<Form.Group inline widths="equal">
				<Form.Input
					fluid
					disabled
					defaultValue={formValues.serialNumber}
					label='Sr. No.'
				/>
				<Form.Input
					fluid
					disabled
					defaultValue={formValues.remark}
					label='Remark'
				/>
				<Form.Checkbox
					label='Request More Information'
					disabled
					checked={formValues.requiresMoreInfo}
				/>
			</Form.Group>
		</Form>
	</Segment>
);
const ConfirmationModalActions = ({action}: { action: string }): JSX.Element => {
	interface ActionDataInterface {
		caseTableData: SocialCaseDetailsInterface,
		caseDetails: SocialGetCaseDetailsResponseInterface,
		context: string,
		reloadFn: () => void
	}

	const loadingModal = useLoadingModal();
	const setModalStack = useSetRecoilState(actionModalStack);
	const [dataStack, setDataStack] = useRecoilState(actionDataStack);
	const formData = useRecoilValue(operationReplyForm);

	const currentUser = useGetUserData();
	const submitAction = useSubmitAction();

	const [calledRequest, setCalledRequest] = useState('');

	const actionError = () => {
		setDataStack([{
			action: 'caseReplyError',
			className: 'smaller',
			data: null,
			onClose: (stackAtomPeek(dataStack) as NestedObject).data.reloadFn
		}]);
		setModalStack([{ content: <GenericErrorMessage message={submitAction.get.message}/> }]);
	};

	const takeAction = async () => {
		const data = Object.assign({}, stackAtomPeek(dataStack)?.data) as ActionDataInterface;
		setModalStack([]);
		setDataStack([]);

		loadingModal.addTask('submitAction');

		const resp = await submitAction.request({
			case_id: data.caseDetails.case_id,
			reply_to_store: false,
			state: {
				current_state: data.caseTableData.status,
				is_sequential_reply: formData.sequentialReply,
				is_require_approval: formData.requiresApproval,
				action: action
			},
			agent: {
				agent_id: (action === OPERATION_ACTION.REASSIGN.id && formData.reassignTo) ?
					formData.reassignTo :
					data.caseDetails.agent_id
				,
				agent_reply_text: formData.text ? formData.text: null,
				serial_no: formData.serialNumber ? formData.serialNumber: null,
				remark: formData.remark ? formData.remark: null,
				request_info: formData.requiresMoreInfo
			},
			admin: {
				approver_id: ([
					OPERATION_ACTION.APPROVE.id,
					OPERATION_ACTION.REJECT.id,
					OPERATION_ACTION.REASSIGN.id
				].includes(action)) ? currentUser.get.userId : null,
				rejected_msg: action === OPERATION_ACTION.REJECT.id ? formData.rejectMsg: null
			},
			types: {
				sentiment_type_id: formData.sentimentType ? formData.sentimentType: null,
				service_type_id: formData.serviceType ? formData.serviceType: null,
				issue_type_id: formData.issueType ? formData.issueType: null,
				category_type_id: formData.category ? formData.category: null
			}
		});

		if (resp.status && resp.status === OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'caseActionSuccess',
				className: 'smaller',
				data: null,
				onClose: data.reloadFn
			}]);
			setModalStack([{
				content: <Message
					success
					icon="check circle"
					header={OPERATION_REQUEST_STATUS.SUCCESS.name}
					content="Executed an action."
				/>
			}]);
		}
		else
			actionError();

		loadingModal.removeTask('submitAction');
	};

	useEffect(() => {
		if (calledRequest) {
			takeAction();
			setCalledRequest('');
		}
	}, [calledRequest]);

	return <Button key="confirm" primary onClick={() => setCalledRequest('confirm')}>Confirm</Button>;
};
const PredefinedAnswerSuggestion = () => {
	const caseDetails = useViewCaseDetails();
	const predefinedAnswers = useGetAnswerList();
	const [formData, setFormData] = useRecoilState(operationReplyForm);
	const [matchedAnswers, setMatchedAnswers] = useState<PredefinedAnswer[]>([]);

	const setResponseText = (text: string) => setFormData({
		...formData,
		text: (
			(formData.text ? formData.text: '')
			+ (text ? text: '')
		),
	});

	useLayoutEffect(() => {
		predefinedAnswers.set(caseDetails.get.predef_answers);
	}, []);
	useEffect(() => {
		setMatchedAnswers(
			predefinedAnswers.get
				.filter(item => item.category_id === formData.category
					&& item.issue_id === formData.issueType)
		);
	}, [formData.category]);
	return matchedAnswers.length ?
		<Segment basic className="answer-suggestion">
			{matchedAnswers
				.slice(0)	// dupe so the useEffect() above won't trip itself smh
				.reverse()
				.slice(0, QUICK_PREDEF_ANSWER_COUNT)
				.map((item, i) =>
					<Button
						circular
						key={i}
						title={item.answer}
						onClick={() => setResponseText(item.answer)}
					>{item.answer}</Button>
			)}
			{matchedAnswers.length > QUICK_PREDEF_ANSWER_COUNT ?
				<Popup
					basic
					className="more-suggestion"
					position="right center"			
					flowing
					hoverable
					trigger={<Button circular className="more-predef-answers">...</Button>}
					content={
						<List celled>
							{matchedAnswers
								.map((item, i) =>
									<List.Item
										key={i}
										onClick={() => setResponseText(item.answer)}
									>{item.answer}</List.Item>
							)}
						</List>
					}
				/> : ''
			}
		</Segment>
		: <></>;
};
const CaseReplyForm = ({platform}: { platform: string }): JSX.Element => {
	interface DropdownValueInterface {
		key: string,
		text: string,
		value: number
	}
	const [formData, setFormData] = useRecoilState(operationReplyForm);

	const [categories, setCategories] = useState<DropdownValueInterface[]>();
	const [initialized, setInitialized] = useState(false);
	const [responseTextDisabled, setResponseTextDisabled] = useState(false);
	const [sentimentClassName, setSentimentClassName] = useState('');
	const [responseLength, setResponseLength] = useState(0);
	const [maxResponseLength, setMaxResponseLength] = useState(0);

	const mapIssueTypes = () => Object.values(CASES_ISSUE_TYPE);
	const getCategories = (value: number) => Object.values(CASES_ISSUE_TYPE).find(e => e.value === value)?.categories;
	const changeHandler = (obj: FlatObject) => setFormData(Object.assign({}, formData, obj));
	const getResponseTextDisability = () => ((
		formData.issueType === CASES_ISSUE_TYPE.COMPLAINT.value &&
		formData.category === CASES_CATEGORY.COMPLAIN.value
	) ||
	(
		formData.issueType === CASES_ISSUE_TYPE.OTHER.value &&
		formData.category === CASES_CATEGORY.ILLEGIBLE.value
	));
	const changeSentimentClassName = (value: number) => {
		const className = Object.values(CASES_SENTIMENT)
			.find(e => e.value === value)
			?.className;
		setSentimentClassName(className ? className: '');
	};

	useLayoutEffect(() => {
		const value = Object.values(SOCIAL_PLATFORMS).find(e => e.id === platform)?.maxLength.reply;

		setResponseLength(formData.text ? formData.text.length: 0);
		setMaxResponseLength(value ? value: 0);
	}, []);
	useEffect(() => {
		if (!initialized && formData.issueType) {
			setInitialized(true);
			setCategories(getCategories(formData.issueType));
		}
		else {
			changeSentimentClassName(formData.sentimentType);
			setResponseTextDisabled(getResponseTextDisability());
		}
	}, [formData]);
	useEffect(() => {
		setResponseLength(formData.text ? formData.text.length: 0);
	}, [formData.text]);

	return (
		<Segment secondary className="operation-reply-form">
			<Header as="h2">Response</Header>
			<Form reply>
				<Form.Group inline widths="equal">
					<Form.Select
						fluid
						value={formData.issueType ? formData.issueType : 0}
						label='Category Level 1'
						options={mapIssueTypes()}
						onChange={(e, target) => {
							const newCategories = getCategories(target.value as number);
							setCategories(newCategories);
							changeHandler({ issueType: target.value, category: newCategories ? newCategories[0].value: 0 });
						}}
					/>
					<Form.Select
						fluid
						value={formData.category ? formData.category : 0}
						label='Category Level 2'
						options={categories ? categories: []}
						onChange={(e, target) => changeHandler({ category: target.value })}
					/>
					<Form.Select
						fluid
						value={formData.sentimentType ? formData.sentimentType : 0}
						label='Sentiment'
						options={Object.values(CASES_SENTIMENT)}
						onChange={(e, target) => {
							changeHandler({ sentimentType: target.value });
							changeSentimentClassName(target.value as number);
						}}
						className={sentimentClassName}
					/>
				</Form.Group>
				<Form.Group inline widths="equal" className="response-text">
					<Label basic size="large" className="response-text">Response Text</Label>
					{!responseTextDisabled && <PredefinedAnswerSuggestion/>}
					<Form.TextArea
						className={(maxResponseLength - responseLength < 0) ? "text-over-limit": ""}
						value={formData.text}
						disabled={responseTextDisabled}
						onChange={(e, target) => {
							const responseText = target.value as string;
							changeHandler({ text: responseText });
						}}
						rows={5}
					/>
					{!responseTextDisabled && <Label
						className={(maxResponseLength - responseLength < 0) ? "text-over-limit": ""}
						icon="bars"
						content={maxResponseLength - responseLength}
					/>}
				</Form.Group>
				<Form.Group inline widths="equal">
					<Form.Input
						fluid
						defaultValue={formData.serialNumber}
						label='Sr. No.'
						onChange={(e, target) => changeHandler({ serialNumber: target.value })}
					/>
					<Form.Input
						fluid
						defaultValue={formData.remark}
						label='Remark'
						onChange={(e, target) => changeHandler({ remark: target.value })}
					/>
					<Form.Checkbox
						label='Request More Information'
						checked={formData.requiresMoreInfo}
						onChange={(e, target) => changeHandler({ requiresMoreInfo: target.checked })}
					/>
				</Form.Group>
				{
					Object.values(OPERATION_STATE).find(e => e.id === formData.status)?.name === OPERATION_STATE.BEING_REVIEWED.name ? 
					<Form.TextArea
						defaultValue={formData.rejectMsg}
						label='Reject Message'
						onChange={(e, target) => changeHandler({ rejectMsg: target.value })}
						rows={2}
					/>: ''
				}
			</Form>
		</Segment>
	);
};
const ReassignModalContent = (): JSX.Element => {
	const [formData, setFormData] = useRecoilState(operationReplyForm);
	const dataStack = useRecoilValue(actionDataStack);

	const users = useGetUsers();

	const [assignToID, setAssignToID] = useState('');

	useEffect(() => {
		const caseAgentId = (stackAtomPeek(dataStack)?.data as NestedObject)
			.caseDetails.agent_id as string
		;
		if (caseAgentId && !assignToID) setAssignToID(caseAgentId);
	}, [dataStack]);

	return (
		<Segment secondary className="operation-reply-form reassign">
			<Form reply>
				<Form.Select
					className="operation-cases-user-filter"
					icon="user"
					label="Select a User"
					fluid
					search
					selection
					value={assignToID}
					options={users.get.data ? users.get.data.map(e => ({
						key: e.id,
						value: e.id,
						text: e.name.givenName + ' ' + e.name.familyName
					})): []}
					onChange={(e, val) => {
						setAssignToID(val.value as string);
						if (formData) setFormData(Object.assign({}, formData, { reassignTo: val.value }));
					}}
				/>
			</Form>
		</Segment>
	);
};
export const TakeActionModalContent = ({caseDetails}: {
	caseDetails: SocialGetCaseDetailsResponseInterface
}): JSX.Element => {
	const dataStack = useRecoilValue(actionDataStack);

	return (
		<Segment basic className="operation-reply-modal">
			<ReviewLayout basic
				author={caseDetails.author}
				date={caseDetails.post_date}
				text={caseDetails.text}
				rating={caseDetails.rating}
			/>
			<CaseInfoLayout
				caseTableData={
					(stackAtomPeek(dataStack)?.data as FlatObject)
						.caseTableData as SocialCaseDetailsInterface
				}
			/>
			<CaseHistoryLayout caseDetails={caseDetails}/>
			<CaseReplyForm
				platform={
					(stackAtomPeek(dataStack)?.data as NestedObject)
						.caseTableData.src as string
				}
			/>
		</Segment>
	);
};
export const TakeActionModalActions = (): JSX.Element => {
	const [modalStack, setModalStack] = useRecoilState(actionModalStack) as unknown as RecoilAtom<FlatObject[]>;
	const [dataStack, setDataStack] = useRecoilState(actionDataStack) as unknown as RecoilAtom<FlatObject[]>;
	const [formData, setFormData] = useRecoilState(operationReplyForm);

	const currentUser = useGetUserData();

	const [context, setContext] = useState<string[]>([]);
	const [replyButtonDisabled, setReplyButtonDisabled] = useState(true);
	const [ignoreButtonDisabled, setIgnoreButtonDisabled] = useState(true);
	const [responseLength, setResponseLength] = useState(0);
	const [maxResponseLength, setMaxResponseLength] = useState(0);

	const getRequiresApproval = () => {
		switch (formData.issueType) {
			case CASES_ISSUE_TYPE.COMPLAINT.value:
				switch (formData.category) {
					case CASES_CATEGORY.RESOLVING.value:
					case CASES_CATEGORY.RESOLVED.value:
					case CASES_CATEGORY.UNCLEAR.value:
					case CASES_CATEGORY.MAINTENANCE.value:
					case CASES_CATEGORY.COMPLAIN.value:
					case CASES_CATEGORY.NEW_CASE.value:
						return false;
					case CASES_CATEGORY.OTHER.value:
						return true;
				}
				break;

			case CASES_ISSUE_TYPE.COMPLIMENT.value:
			case CASES_ISSUE_TYPE.FEEDBACK.value:
				return false;

			case CASES_ISSUE_TYPE.INQUIRY.value:
				switch (formData.category) {
					case CASES_CATEGORY.INFO.value: return false;
					case CASES_CATEGORY.OTHER.value: return true;
				}
				break;

			case CASES_ISSUE_TYPE.OTHER.value:
				switch (formData.category) {
					case CASES_CATEGORY.ENGLISH.value:
						return true;
					case CASES_CATEGORY.ILLEGIBLE.value:
					case CASES_CATEGORY.ENGLISH_AGENT.value:
						return false;
				}
				break;

			default:
				return false;
		}
	};
	const getSequentialReply = () => (
		formData.issueType === CASES_ISSUE_TYPE.COMPLAINT.value &&
		formData.category === CASES_CATEGORY.RESOLVING.value
	);
	const setActionButtonStates = () => {
		if (currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id) {
			setReplyButtonDisabled(false);
			setIgnoreButtonDisabled(false);
		}
		else
			switch (formData.issueType) {
				case CASES_ISSUE_TYPE.COMPLAINT.value:
					switch (formData.category) {
						case CASES_CATEGORY.RESOLVING.value:
						case CASES_CATEGORY.RESOLVED.value:
						case CASES_CATEGORY.UNCLEAR.value:
						case CASES_CATEGORY.MAINTENANCE.value:
						case CASES_CATEGORY.NEW_CASE.value:
							setReplyButtonDisabled(false);
							setIgnoreButtonDisabled(true);
							return;
						case CASES_CATEGORY.COMPLAIN.value:
							setReplyButtonDisabled(true);
							setIgnoreButtonDisabled(false);
							return;
						case CASES_CATEGORY.OTHER.value:
							setReplyButtonDisabled(false);
							setIgnoreButtonDisabled(false);
							return;
					}
					return;

				case CASES_ISSUE_TYPE.COMPLIMENT.value:
				case CASES_ISSUE_TYPE.FEEDBACK.value:
					setReplyButtonDisabled(false);
					setIgnoreButtonDisabled(true);
					return;

				case CASES_ISSUE_TYPE.INQUIRY.value:
					setReplyButtonDisabled(false);
					switch (formData.category) {
						case CASES_CATEGORY.INFO.value: setIgnoreButtonDisabled(true); return;
						case CASES_CATEGORY.OTHER.value: setIgnoreButtonDisabled(false); return;
					}
					return;

				case CASES_ISSUE_TYPE.OTHER.value:
					switch (formData.category) {
						case CASES_CATEGORY.ENGLISH.value:
						case CASES_CATEGORY.ENGLISH_AGENT.value:
							setReplyButtonDisabled(false);
							setIgnoreButtonDisabled(true);
							return;
						case CASES_CATEGORY.ILLEGIBLE.value:
							setReplyButtonDisabled(true);
							setIgnoreButtonDisabled(false);
							return;
					}
					return;
			}
	};
	const confirmationModal = (action: string) => {
		const requiresApproval = getRequiresApproval();
		const requiresApprovalMsg = !requiresApproval ? '': ' An approval request will be sent.';

		setFormData(Object.assign({}, formData, {
			requiresApproval: requiresApproval,
			sequentialReply: getSequentialReply()
		}));
		stackAtomPush([dataStack, setDataStack], {
			action: 'confirmCaseAction',
			className: 'smaller',
			data: stackAtomPeek(dataStack)?.data
		});
		stackAtomPush([modalStack, setModalStack], Object.assign((() => {
			switch (action) {
				case OPERATION_ACTION.NO_ACT.id: return {
					header: 'Confirm Action: Ignore (No Response)',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content={'Confirm Ignore?' + requiresApprovalMsg}
					/>,
				};
				case OPERATION_ACTION.REPLY.id: return {
					header: 'Confirm Action: Reply',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content={'Confirm Reply?' + requiresApprovalMsg}
					/>
				};
				case OPERATION_ACTION.EDIT.id: return {
					header: 'Confirm Action: Edit Reply',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content={'Confirm Reply Edit?' + requiresApprovalMsg}
					/>
				};
				case OPERATION_ACTION.REVISE.id: return {
					header: 'Confirm Action: Revise Reply',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content={'Confirm Reply Resubmission?' + requiresApprovalMsg}
					/>
				};
				case OPERATION_ACTION.APPROVE.id: return {
					header: 'Confirm Action: Approve',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content="Confirm approval? Any modification to the response will be applied."
					/>
				};
				case OPERATION_ACTION.REJECT.id: return {
					header: 'Confirm Action: Reject',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content="Confirm rejection? Modifications to the response will be sent back to the agent."
					/>
				};
				case OPERATION_ACTION.REASSIGN.id: return {
					header: 'Confirm Action: Reassign',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content="Confirm User Reassignment?"
					/>
				};
				case OPERATION_ACTION.UNCLAIM.id: return {
					header: 'Confirm Action: Unclaim',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content="Confirm Unclaim?"
					/>,
				};
			}})(), {
				actions: <ConfirmationModalActions action={action}/>
			}
		));
	};

	useLayoutEffect(() => {
		const platform = (stackAtomPeek(dataStack)?.data as NestedObject).caseTableData.src as string;
		const value = Object.values(SOCIAL_PLATFORMS).find(e => e.id === platform)?.maxLength.reply;

		setResponseLength(formData.text ? formData.text.length: 0);
		setMaxResponseLength(value ? value: 0);
	}, []);
	useLayoutEffect(() => {
		const actionData = stackAtomPeek(dataStack);

		if (actionData?.action === 'takeAction') {
			setContext((actionData as NestedObject)?.data.context as string[]);
		}
		else if (actionData?.action === 'reassign') {
			setContext([OPERATION_ACTION.REASSIGN.id]);
		}
	}, [dataStack]);
	useEffect(setActionButtonStates, [formData]);
	useEffect(() => {
		setResponseLength(formData.text ? formData.text.length: 0);
	}, [formData.text]);

	return <>
		{context.map(e => {
			switch (e) {
				case OPERATION_ACTION.REPLY.id:
					return <Button
						key="reply"
						disabled={replyButtonDisabled || maxResponseLength - responseLength < 0}
						primary
						onClick={() => confirmationModal(OPERATION_ACTION.REPLY.id)}
					>
						Reply
					</Button>;
				case OPERATION_ACTION.NO_ACT.id:
					return <Button
						key="noAction"
						disabled={ignoreButtonDisabled}
						onClick={() => confirmationModal(OPERATION_ACTION.NO_ACT.id)}
					>
						Ignore
					</Button>;
				case OPERATION_ACTION.EDIT.id:
					return <Button
						key="edit"
						disabled={maxResponseLength - responseLength < 0}
						primary
						onClick={() => confirmationModal(OPERATION_ACTION.EDIT.id)}
					>
						Edit Reply
					</Button>;
				case OPERATION_ACTION.REVISE.id:
					return <Button
						key="revise"
						disabled={maxResponseLength - responseLength < 0}
						primary
						onClick={() => confirmationModal(OPERATION_ACTION.REVISE.id)}
					>
						Revise Reply
					</Button>;
				case OPERATION_ACTION.APPROVE.id:
					return <Button
						key="approve"
						disabled={maxResponseLength - responseLength < 0}
						primary
						onClick={() => confirmationModal(OPERATION_ACTION.APPROVE.id)}
					>
						Approve
					</Button>;
				case OPERATION_ACTION.REJECT.id:
					return <Button key="reject" onClick={() => confirmationModal(OPERATION_ACTION.REJECT.id)}>
						Reject
					</Button>;
				case OPERATION_ACTION.REASSIGN.id:
					if (stackAtomPeek(dataStack)?.action !== 'takeAction')
						return <Button key="reassign" primary onClick={() => confirmationModal(OPERATION_ACTION.REASSIGN.id)}>
							Reassign
						</Button>;
					else break;
				default: return '';
			}
		})}
	</>;
};
const ViewCaseDetailsModalContent = (): JSX.Element => {
	const dataStack = useRecoilValue(actionDataStack);
	const setReplyFormData = useSetRecoilState(operationReplyForm);
	
	const [caseDetails, setCaseDetails] = useState({} as SocialGetCaseDetailsResponseInterface);
	const [replyViewData, setReplyViewData] = useState<SocialReplyFormDataInterface>({} as SocialReplyFormDataInterface);

	useLayoutEffect(() => {
		const actionData = stackAtomPeek(dataStack);
		if (actionData?.action === 'viewCaseDetails') {
			const data = actionData.data as FlatObject;
			const tableData = data.caseTableData as FlatObject;
			const newCaseDetails = data.caseDetails as SocialGetCaseDetailsResponseInterface;
			setCaseDetails(newCaseDetails);
			if (!Object.values(replyViewData).length) {
				const form: SocialReplyFormDataInterface = {
					status: tableData.status as string,
					serialNumber: newCaseDetails.serial_no ?? '',
					rejectMsg: newCaseDetails.rejected_msg ?? '',
					remark: newCaseDetails.remark ?? '',
					text: newCaseDetails.agent_reply_text ?? '',
					serviceType: newCaseDetails.service_type_id ? newCaseDetails.service_type_id: 0,
					issueType: newCaseDetails.issue_type_id ? newCaseDetails.issue_type_id: 0,
					category: newCaseDetails.category_type_id ? newCaseDetails.category_type_id: 0,
					sentimentType: newCaseDetails.sentiment_type_id ? newCaseDetails.sentiment_type_id: 0,
					requiresApproval: tableData.status === OPERATION_STATE.CLAIMED.id ? false : true,
					sequentialReply: false,
					requiresMoreInfo: newCaseDetails.request_info,
				};
				setReplyViewData(Object.assign({}, form));
				setReplyFormData(Object.assign({}, form, { requiresApproval: true }));
			}
		}
	}, [dataStack]);

	return (
		<Segment basic className="operation-reply-modal">
			<ReviewLayout basic
				author={caseDetails.author}
				date={caseDetails.post_date}
				text={caseDetails.text}
				rating={caseDetails.rating}
			/>
			<CaseInfoLayout
				caseTableData={
					(
						stackAtomPeek(dataStack)?.data as FlatObject
					).caseTableData as SocialCaseDetailsInterface
				}
			/>
			<CaseHistoryLayout caseDetails={caseDetails}/>
			<CaseReplyViewLayout formValues={replyViewData}/>
		</Segment>
	);
};
const ViewCaseDetailsModalActions = (): JSX.Element => {
	const [modalStack, setModalStack] = useRecoilState(actionModalStack) as unknown as RecoilAtom<FlatObject[]>;
	const [dataStack, setDataStack] = useRecoilState(actionDataStack) as unknown as RecoilAtom<FlatObject[]>;

	const currentUser = useGetUserData();

	const [caseDetails, setCaseDetails] = useState({} as SocialGetCaseDetailsResponseInterface);
	const [context, setContext] = useState<string[]>([]);

	const takeActionModal = () => {
		const data = stackAtomPeek(dataStack)?.data as NestedObject;
		stackAtomPush([dataStack, setDataStack], { action: 'takeAction', data: data });
		stackAtomPush([modalStack, setModalStack], {
			header: 'Take an Action'
				+ (data.caseTableData.src !== 'Google Play Store' && data.caseTableData.title ?
					`: ${data.caseTableData.title as string}`: ''
			),
			content: <TakeActionModalContent caseDetails={caseDetails}/>,
			actions: <TakeActionModalActions/>
		});
	};
	const reassignModal = () => {
		const data = stackAtomPeek(dataStack)?.data;
		stackAtomPush([dataStack, setDataStack], { action: 'reassign', data: data, className: 'smaller' });
		stackAtomPush([modalStack, setModalStack], {
			header: 'Reassign',
			content: <ReassignModalContent/>,
			actions: <TakeActionModalActions/>
		});
	};

	useLayoutEffect(() => {
		const actionData = stackAtomPeek(dataStack) as NestedObject;
		if (actionData?.action as unknown as string === 'viewCaseDetails') {
			setCaseDetails(actionData.data.caseDetails as SocialGetCaseDetailsResponseInterface);
			setContext(actionData.data.context as string[]);
		}
	}, [dataStack]);

	return <>
		{
			context.filter(e => [
				OPERATION_ACTION.REPLY.id,
				OPERATION_ACTION.NO_ACT.id,
				OPERATION_ACTION.EDIT.id,
				OPERATION_ACTION.REVISE.id,
				OPERATION_ACTION.APPROVE.id,
				OPERATION_ACTION.REJECT.id
			].includes(e)).length ?
			<Button
				primary
				hidden={
					!context ||
					!context.length ||
					(context.length === 1 && context[0] === OPERATION_ACTION.REASSIGN.id)
				}
				onClick={() => takeActionModal()}
			>
				Take Action
			</Button>
			: ''
		}
		{
			(
				currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id &&
				context.filter(e => e === OPERATION_ACTION.REASSIGN.id).length
			) ?
			<Button key="reassign" onClick={() => reassignModal()}>
				Reassign
			</Button>
			: ''
		}
		{
			(
				currentUser.get.role === OPERATION_USER_ROLE.SUPERVISOR.id &&
				context.filter(e => e === OPERATION_ACTION.UNCLAIM.id).length
			) ?
			<Button key="unclaim" onClick={() => {
				stackAtomPush([dataStack, setDataStack], {
					action: 'confirmCaseAction',
					className: 'smaller',
					data: stackAtomPeek(dataStack)?.data
				});
				stackAtomPush([modalStack, setModalStack], Object.assign({
					header: 'Confirm Action: Unclaim',
					content: <Message
						info
						icon="info circle"
						header="Note"
						content="Confirm Unclaim?"
					/>,
					actions: <ConfirmationModalActions action={OPERATION_ACTION.UNCLAIM.id}/>
				}) as FlatObject);
			}}>
				Unclaim
			</Button>
			: ''
		}
	</>;
};
const CaseDataTable = (): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [modalStack, setModalStack] = useRecoilState(actionModalStack) as unknown as RecoilAtom<FlatObject[]>;
	const [dataStack, setDataStack] = useRecoilState(actionDataStack) as unknown as RecoilAtom<FlatObject[]>;
	const setFormData = useSetRecoilState(operationReplyForm);
	const [tableColumnLabels, setTableColumnLabels] = useRecoilState(casesColumnLabels);

	const cases = useViewCases();
	const currentUser = useGetUserData();
	const caseDetails = useViewCaseDetails();

	const [tableData, setTableData] = useState<SocialCasesDataTableInterface[]>([]);
	const [rowProps, setRowProps] = useState<NodeJS.Dict<unknown>[]>([]);

	const reload = async () => {
		loadingModal.addTask('getCases');

		const response = await cases.request({}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getCasesError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}

		loadingModal.removeTask('getCases');
	};
	const getCaseDetailsContext = (status: string, response: SocialGetCaseDetailsResponseInterface) => {
		const state = OPERATION_STATE;
		const user = currentUser.get;

		if (user.role && user.role === OPERATION_USER_ROLE.SUPERVISOR.id)
			switch (status) {
				case state.CLAIMED.id:	// Note: Should display only supervisor's own cases
				case state.NEED_ACTION.id:
					return response.agent_id === user.userId ? [
						OPERATION_ACTION.REPLY.id,
						OPERATION_ACTION.NO_ACT.id,
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					]: [
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					];
				case state.BEING_RESOLVED.id:
					return response.agent_id === user.userId ? [
						OPERATION_ACTION.REPLY.id,
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					]: [
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					];
				case state.CLOSED.id:
				case state.CLOSED_WITH_NO_ACT.id:
				case state.USER_UPDATED.id:
					return response.agent_id === user.userId ? [
						OPERATION_ACTION.EDIT.id,
						OPERATION_ACTION.REASSIGN.id
					]: [OPERATION_ACTION.REASSIGN.id];
				case state.REJECTED.id:
				case state.RESOLVE_REJECTED.id:
				case state.EDIT_REJECTED.id:
					return response.agent_id === user.userId ? [
						OPERATION_ACTION.REVISE.id,
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					]: [
						OPERATION_ACTION.REASSIGN.id,
						OPERATION_ACTION.UNCLAIM.id
					];
				case state.BEING_REVIEWED.id:
				case state.REVISED.id:
				case state.BEING_REVIEWED_FOR_NO_ACT.id:
				case state.BEING_REVIEWED_FOR_RESOLVE.id:
				case state.REVISED_RESOLVE.id:
				case state.BEING_REVIEWED_FOR_EDIT.id:
				case state.REVISED_EDIT.id:
					return [
						OPERATION_ACTION.APPROVE.id,
						OPERATION_ACTION.REJECT.id
					];
			}
		else
			switch (status) {
				case state.CLAIMED.id:
				case state.NEED_ACTION.id:
				case state.BEING_RESOLVED.id:
					return [
						OPERATION_ACTION.REPLY.id,
						OPERATION_ACTION.NO_ACT.id
					];
				case state.CLOSED.id:
				case state.CLOSED_WITH_NO_ACT.id:
				case state.USER_UPDATED.id:
					return [OPERATION_ACTION.EDIT.id];
				case state.REJECTED.id:
				case state.RESOLVE_REJECTED.id:
				case state.EDIT_REJECTED.id:
					return [OPERATION_ACTION.REVISE.id];
				case state.BEING_REVIEWED.id:
				case state.REVISED.id:
				case state.BEING_REVIEWED_FOR_NO_ACT.id:
				case state.BEING_REVIEWED_FOR_RESOLVE.id:
				case state.REVISED_RESOLVE.id:
				case state.BEING_REVIEWED_FOR_EDIT.id:
				case state.REVISED_EDIT.id:
					return [];
			}
	};
	const updateTableData = () => {
		setTableData(
			cases.get.data.map(e => Object.assign({}, e)).map(
				e => {
					const status = Object.values(OPERATION_STATE).find(ee => ee.id === e.status);
					return {
						postDate: e.post_datetime.toLocaleString(),
						latestUpdateDate: e.history_datetime.toLocaleString(),
						rating: <Rating title={e.rating + '/5'} rating={e.rating} maxRating={5} disabled />,
						status: status ? <span title={status.title}>{status.name}</span> : '',
						author: e.author,
						text: <div className="long-text" title={e.text}>{e.text}</div>,
						operator: e.staff_name,
						slaTimeRemaining: getRemainingSLAString(e.sla)
					};
				}
			)
		);
		setRowProps (
			cases.get.data.map(e => {
				if (e.sla > 7200)		// 2 hrs+
					return { className: 'sla-green clickable' };
				else if (e.sla > 3600)	// 30 min+
					return { className: 'sla-yellow clickable' };
				else
					return { className: 'sla-red clickable' };
			})
		);
	};

	const pageChangeHandler = async ({ pageOffset }: PageArgsInterface) => {
		const response = await cases.request({
			offset_start: pageOffset,
			offset_end: pageOffset + TABLE_PAGELENGTH - 1,
		}, true);
		if (response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
			setDataStack([{
				action: 'getCasesError',
				className: 'smaller',
				data: null,
				locked: true
			}]);
			setModalStack([{
				content: <GenericErrorMessage message={response.message}/>,
				actions: <ReloadPageButton/>
			}]);
		}
	};
	const viewCaseDetails = async ({ index }: { index?: number }) => {
		if (typeof index === 'number') {
			loadingModal.addTask('getCaseDetails');

			const caseTableData = Object.assign({}, cases.get).data[index];
			const response = await caseDetails.request({ case_id: caseTableData.case_id });
			if (response.status && response.status !== OPERATION_REQUEST_STATUS.SUCCESS.id) {
				setDataStack([{
					action: 'getCaseDetailsError',
					className: 'smaller',
					data: null,
					onClose: reload
				}]);
				setModalStack([{ content: <GenericErrorMessage message={response.message}/>
				}]);
			}
			else {
				stackAtomPush([dataStack, setDataStack], {
					action: 'viewCaseDetails',
					data: {
						caseTableData: caseTableData,
						caseDetails: response,
						context: getCaseDetailsContext(caseTableData.status, response),
						reloadFn: reload
					}
				});
				stackAtomPush([modalStack, setModalStack], {
					header: 'Case Details' + (caseTableData.src !== 'Google Play Store' && caseTableData.title ? ': ' + caseTableData.title : ''),
					content: <ViewCaseDetailsModalContent/>,
					actions: <ViewCaseDetailsModalActions/>
				});
			}

			loadingModal.removeTask('getCaseDetails');
		}
	};

	useLayoutEffect(() => {
		setTableColumnLabels({
			postDate: 'Posted',
			latestUpdateDate: 'Updated',
			rating: 'Rating',
			status: 'Status',
			author: 'Author',
			text: 'Content',
			operator: 'Case Owner',
			slaTimeRemaining: 'Remaining SLA Time'
		});
		return () => cases.set({} as SocialGetCasesResponseInterface);
	}, []);
	useLayoutEffect(() => { if (cases.get.data) updateTableData(); }, [cases.get]);
	useLayoutEffect(() => { if (!dataStack.length) setFormData({} as SocialReplyFormDataInterface); }, [dataStack]);

	return (
		<Segment className="operation-data-table" basic>
			<DataTable
				attached="bottom"
				columns={tableColumnLabels}
				data={tableData as unknown as NodeJS.Dict<unknown>[]}
				rowComponents={(<a/>)}
				rowClick={viewCaseDetails}
				rowProps={rowProps}
				pageLength={TABLE_PAGELENGTH}
				totalDataLength={cases.get.total}
				paginationService={pageChangeHandler}
				selectable
			/>
		</Segment>
	)
};

export const ViewCases = (): JSX.Element => <Tab.Pane as={Container}>
	<CaseSearchForm/>
	<CaseResultsInfo/>
	<CasePlatformButtonGroup/>
	<CaseDataTable/>
</Tab.Pane>;