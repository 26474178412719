import { useRecoilState } from "recoil";
import { submitAction as APISubmitAction } from "../../actions/submitAction";
import { SocialSubmitActionRequestInterface, SocialSubmitActionResponseInterface } from "../../models/operation-submit-action";
import { operationSubmitActionResponse } from "../../recoil-atoms/operation";

export const useSubmitAction = () : {
	get: SocialSubmitActionResponseInterface,
	set: (posts: SocialSubmitActionResponseInterface) => void,
	request: (payload: SocialSubmitActionRequestInterface) => Promise<SocialSubmitActionResponseInterface>,
} => {
	const [submitAction, setSubmitAction] = useRecoilState(operationSubmitActionResponse);

	const httpGetSubmitAction = async (payload: SocialSubmitActionRequestInterface) => {
		const resp = await APISubmitAction(payload);

		setSubmitAction(resp);
		return resp;
	};

	return {
		get: submitAction,
		set: setSubmitAction,
		request: httpGetSubmitAction,
	};
}