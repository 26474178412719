import { useRecoilState, useRecoilValue } from "recoil";
import { operationLoadingModal, operationLoadingModalTasks, operationLoadingModalDelayReached } from "../recoil-atoms/operation";

export const useLoadingModal = (): {
	tasks: string[],
	addTask: (id: string) => void,
	removeTask: (id: string) => void
} => {
	const minLoadingDelay = 250;	// ms
	const opLoadingModal = useRecoilValue(operationLoadingModal);
	const [loadingDelayReached, setLoadingDelayReached] = useRecoilState(operationLoadingModalDelayReached);
	const tasks = useRecoilValue(operationLoadingModalTasks);

	const addTask = (id: string) => {
		if (tasks.indexOf(id) < 0) {
			tasks.push(id);
			if (tasks.length === 1) {
				if (opLoadingModal.showLoading)
					opLoadingModal.showLoading();
				setLoadingDelayReached(false);
				setTimeout(() => setLoadingDelayReached(true), minLoadingDelay);
			}
		}
	};
	const removeTask = (id: string) => {
		const idx = tasks.indexOf(id);
		if (idx >= 0) {
			tasks.splice(idx, 1);
			if (!tasks.length && loadingDelayReached && opLoadingModal.hideLoading)
				opLoadingModal.hideLoading();
		}
	};

	return {
		tasks: tasks,
		addTask: addTask,
		removeTask: removeTask
	};
}