import React from "react";
import { Modal, Button } from "react-bootstrap";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { DateRangePicker } from "react-date-range";

class CalendarModal extends React.Component<any> {
  state = {
    dateRange: {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  };

  componentDidMount() {
    this.setState({
      dateRange: {
        startDate: this.props.startDate,
        endDate: this.props.endDate,
        key: "selection",
      },
    });
  }

  handleSelect = (ranges: any) => {
    this.setState({ dateRange: ranges.selection });
  };
  render() {
    return (
      <Modal
        show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              Please select date range
            </Modal.Title>
          </Modal.Header>
          <DateRangePicker
            ranges={[this.state.dateRange]}
            onChange={this.handleSelect}
            months={2}
            direction="horizontal"
          />
          <Modal.Footer>
            <Button
              variant="success"
              onClick={() => {
                this.props.setDateRange(this.state.dateRange);
                this.props.setToggleCalendar()
              }}
            >
              Pick
            </Button>
            <Button variant="danger" onClick={this.props.setToggleCalendar}>
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default CalendarModal;
