import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Input, Segment } from "semantic-ui-react";
import { generateLucene } from "../utils/Helper";
import { ChromePicker } from "react-color";
class PresetEditModal extends React.Component<any> {
  state = {
    searchQuery: "",
    searchName: "",
    searchLucene: "",
    saveSearchQuery: "",
    saveSearchText: "",
    color: "",
    colorPreset: null,
    groupName: "",
    isBankSearch: true,
  };

  componentDidMount() {
    const lucene = generateLucene(this.props.searchText);
    let luceneSplit = null;
    let color = "";
    let colorPreset = null;
    let groupName = "";
    let isBankSearch = true;
    if (!this.props.preset.isBankSearch) {
      luceneSplit = this.props.preset.lucene
        .split("|")
        [this.props.group].split(":");
      color = this.props.preset.color[luceneSplit[1].toLowerCase()];
      colorPreset = { ...this.props.preset.color };
      groupName = luceneSplit[1].toLowerCase();
      isBankSearch = false;
    }
    this.setState({
      searchQuery: this.props.searchText,
      searchName: this.props.searchName,
      searchLucene: lucene,
      saveSearchQuery: this.props.preset.lucene,
      saveSearchText: this.props.preset.searchText,
      color: color,
      colorPreset: colorPreset,
      groupName: groupName,
      isBankSearch: isBankSearch,
    });
  }
  handleChangeComplete = (color: any) => {
    let colorPreset: any = this.state.colorPreset;
    colorPreset[this.state.groupName] = color.hex;
    this.setState({ color: color.hex, colorPreset: colorPreset });
  };
  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.topic}</h4>
            <p>{this.props.description}</p>
            <Input
              fluid
              focus
              placeholder="Search query (Put AND/OR for logical search query)"
              onChange={(event) => {
                const caret = event.target.selectionStart;
                const element = event.target;
                window.requestAnimationFrame(() => {
                  element.selectionStart = caret;
                  element.selectionEnd = caret;
                });

                let text = event.target.value;
                text = text.toLowerCase();
                text = text.replace(/ AND /gim, " AND ");
                text = text.replace(/ OR /gim, " OR ");
                const lucene = generateLucene(text);
                let saveSearchQuery = lucene;
                let saveSearchText = text;
                if (!this.state.isBankSearch) {
                  let tmpSplit = this.props.preset.lucene.split("|");
                  let editGroup = lucene + ":" + this.state.groupName;
                  tmpSplit[this.props.group] = editGroup;
                  saveSearchQuery = tmpSplit.join("|");
                  tmpSplit = this.props.preset.searchText.split("|");
                  editGroup = text + ":" + this.state.groupName;
                  tmpSplit[this.props.group] = editGroup;
                  saveSearchText = tmpSplit.join("|");
                }
                this.setState({
                  searchQuery: text,
                  searchLucene: lucene,
                  saveSearchQuery: saveSearchQuery,
                  saveSearchText: saveSearchText,
                });
              }}
              value={this.state.searchQuery}
            />
            <Segment style={{ color: "#89f536" }} size={"mini"} inverted>
              {this.state.searchLucene}
            </Segment>
            {this.props.preset.isBankSearch ? null : (
              <ChromePicker
                // width="100%"   TODO: What does this "width" do? This gives error rn.
                color={this.state.color}
                onChangeComplete={this.handleChangeComplete}
              />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={
                this.state.searchQuery.length === 0 ||
                generateLucene(this.state.searchQuery) === "Invalid!!"
              }
              variant={this.props.button1Style}
              onClick={() => {
                this.props.callBack1(
                  this.state.searchName,
                  this.state.saveSearchText,
                  this.state.saveSearchQuery,
                  this.state.isBankSearch,
                  this.state.colorPreset
                );
                this.props.callBack2();
              }}
            >
              {this.props.button1}
            </Button>
            <Button
              variant={this.props.button2Style}
              onClick={this.props.callBack2}
            >
              {this.props.button2}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default PresetEditModal;
