import React, {
	ReactNode,
	useEffect,
	useLayoutEffect,
	useState
} from "react";

import {
	Button,
	Container,
	Icon,
	Modal,
	Segment,
	Tab
} from "semantic-ui-react";
import { ViewPosts as Posts } from "./operation-posts";
import { ViewCases as Cases } from "./operation-cases";

import { connect } from "react-redux";
import { useRecoilState, useRecoilValue } from "recoil";
import { changePage } from "../../actions";
import { stackAtomPeek, stackAtomPop } from "../utils/recoil-helpers";
import {
	operationRole,
	operationActionModalStack as actionModalStack,
	operationActionDataStack as actionDataStack,
	operationLoadingModal
} from '../recoil-atoms/operation';

import '../css/operation.css';
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { useGetUserData } from "../hooks/services/operation-get-user-data";
import { useGetUsers } from "../hooks/services/operation-get-users";
import { OPERATION_USER_ROLE } from "../configs/operation-ui";
import { FlatObject, RecoilAtom } from "../models/known-types";

export const OperationCurrentRoleLabel = (): JSX.Element => {
	const userData = useGetUserData();
	
	return userData.get.role ? (
		<span>
			<>Role: </>
			{(() => {
				const role = Object.values(OPERATION_USER_ROLE).find(e => e.id === userData.get.role)
				return role ? <a>{role.name}</a>: '(No Role)';
			})()}
		</span>
	): <span/>;
};

const OperationMainTab = (): JSX.Element => {
	const role = useRecoilValue(operationRole);

	const [panes, setPanes] = useState<NodeJS.Dict<unknown>[]>([]);

	useLayoutEffect(() => {
		const mainPanes = [
			{
				menuItem: {
					key: 'posts',
					content: (
						<div>
							<Icon name="clone"/>
							<span> Posts</span>
						</div>
					)
				},
				render: () => <Posts/>
			}, {
				menuItem: {
					key: 'cases',
					content: (
						<div>
							<Icon name="chat"/>
							<span> Cases</span>
						</div>
					)
				},
				render: () => <Cases/>
			}
		];
		setPanes(mainPanes);
	}, [role]);

	return (
		<Tab menu={{
			className: 'segment-tab',
			secondary: true,
			pointing: true,
			widths: panes.length ? panes.length : undefined
		}} panes={panes}/>
	)
};

const OperationActionModal = (): JSX.Element => {
	const [modalStack, setModalStack] = useRecoilState(actionModalStack) as unknown as RecoilAtom<FlatObject[]>;
	const [dataStack, setDataStack] = useRecoilState(actionDataStack) as unknown as RecoilAtom<FlatObject[]>;

	const closeNavigationHandler = () => {
		const activeModalData = stackAtomPeek(dataStack) as {
			onClose: () => unknown,
			[key: string]: unknown
		};

		if (modalStack.length) {
			setModalStack([]);
			setDataStack([]);
		}
		if (activeModalData && activeModalData.onClose)
			activeModalData.onClose();
	};
	const backNavigationHandler = () => {
		if (modalStack.length) {
			stackAtomPop([modalStack, setModalStack]);
			stackAtomPop([dataStack, setDataStack]);
		}
	};

	return (
		<Modal
			open={modalStack.length ? true : false}
			closeOnDimmerClick={false}
			className={(() => {
				const data = dataStack.length ?
					dataStack[dataStack.length - 1]
					: null
				;
				return 'operation-action-modal' + ((data && data.className) ? ' ' + (data.className as string): '');
			})()}
		>
			{
				modalStack.length && modalStack[modalStack.length - 1].header ?
					<Modal.Header>{modalStack[modalStack.length - 1].header as ReactNode}</Modal.Header>
			: ''}
			<Modal.Content>{modalStack.length ? modalStack[modalStack.length - 1].content as ReactNode: ''}</Modal.Content>
			<Modal.Actions>
				<Button
					onClick={closeNavigationHandler}
					floated="left"
					color="red"
					hidden={!dataStack.length || dataStack[dataStack.length - 1].locked}
				>
					Close
				</Button>
				<Button
					onClick={backNavigationHandler}
					floated="left"
					hidden={modalStack.length > 1 ? false : true}
				>
					Back
				</Button>
				<Button disabled className="padding-placeholder">.</Button>
				{modalStack.length && modalStack[modalStack.length - 1].actions}
			</Modal.Actions>
		</Modal>
	);
};

const OperationMainPage = (props: {
	showLoading: () => void,
	hideLoading: () => void
}): JSX.Element => {
	const loadingModal = useLoadingModal();
	const [loadingModalHandlers, setLoadingModalHandlers] = useRecoilState(operationLoadingModal);

	const userData = useGetUserData();
	const users = useGetUsers();

	const getUserData = async () => {
		loadingModal.addTask('getUserData');
		loadingModal.addTask('getUsers');
		await userData.request();
		loadingModal.removeTask('getUserData');
		await users.request();
		loadingModal.removeTask('getUsers');
	};

	useLayoutEffect(() => {
		setLoadingModalHandlers({
			showLoading: props.showLoading,
			hideLoading: props.hideLoading
		});
	}, []);
	useEffect(() => {
		if (loadingModalHandlers.showLoading)
			getUserData();
	}, [loadingModalHandlers]);

	return <Segment className="operation">
		<OperationMainTab/>
		<OperationActionModal/>
	</Segment>;
};

// container class component wrap + redux adaptation
class Operation extends React.Component<{
	showLoading: () => void,
	hideLoading: () => void
}> {
	render = () => {
		return (
			<Container className="operation page-content">
				<OperationMainPage
					showLoading={this.props.showLoading}
					hideLoading={this.props.hideLoading}
				/>
			</Container>
		);
	};
};

export default connect(null, { changePage })(Operation);