import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError } from "../../models/http";
import { SocialGetCaseDetailsRequestInterface, SocialGetCaseDetailsResponseInterface } from "../models/operation-get-case-details";
import { OPERATION_ROUTE } from "../configs/operation-api";

export const getCaseDetails = async (payload: SocialGetCaseDetailsRequestInterface): Promise<SocialGetCaseDetailsResponseInterface> => {
	const response =
		await axios.post
			<SocialGetCaseDetailsResponseInterface>(
				OPERATION_ROUTE.GET_CASE_DETAILS,
				payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data;
};