import { SocialGetCasesRequestInterface } from "../models/operation-get-cases";
import { SocialGetPostsRequestInterface } from "../models/operation-get-posts";
import {
	SOCIAL_PLATFORMS,
	TABLE_PAGELENGTH,
	CASES_STATUS_FILTER
} from "./operation-ui";

export const OPERATION_ROUTE = {
	GET_USER_DATA: '/api/getUserData',
	GET_USERS: '/api/getUsers',

	GET_POSTS: '/api/getPosts',
	GET_CASE_DETAILS: '/api/getCaseDetails',
	CLAIM_POST: '/api/claim',
	SUBMIT_ACTION: '/api/submit',
};

export const OPERATION_STATE = {
	AVAILABLE: {
		id: 'AVAILABLE',
		name: 'New',
		title: 'Available'
	},
	CLAIMED: {
		id: 'CLAIMED',
		name: 'Processing',
		title: 'Claimed'
	},
	CLOSED: {
		id: 'CLOSED',
		name: 'Completed',
		title: 'Closed (Responded)'
	},
	BEING_REVIEWED: {
		id: 'BEING_REVIEWED',
		name: 'Waiting for Approval',
		title: 'Pending Approval'
	},
	REJECTED: {
		id: 'REJECTED',
		name: 'Processing',
		title: 'Rejected'
	},
	REVISED: {
		id: 'REVISED',
		name: 'Waiting for Approval',
		title: 'Pending Approval (Revision)'
	},
	BEING_REVIEWED_FOR_NO_ACT: {
		id: 'BEING_REVIEWED_FOR_NO_ACT',
		name: 'Waiting for Approval',
		title: 'Pending Approval (No Action)'
	},
	NEED_ACTION: {
		id: 'NEED_ACTION',
		name: 'Processing',
		title: 'Action Needed'
	},
	CLOSED_WITH_NO_ACT: {
		id: 'CLOSED_WITH_NO_ACT',
		name: 'Closed',
		title: 'Closed (No Action)'
	},
	BEING_RESOLVED: {
		id: 'BEING_RESOLVED',
		name: 'Follow Up',
		title: 'Ongoing Case'
	},
	BEING_REVIEWED_FOR_RESOLVE: {
		id: 'BEING_REVIEWED_FOR_RESOLVE',
		name: 'Waiting for Approval',
		title: 'Pending Approval (Ongoing Case)'
	},
	RESOLVE_REJECTED: {
		id: 'RESOLVE_REJECTED',
		name: 'Processing',
		title: 'Rejected (Ongoing Case)'
	},
	REVISED_RESOLVE: {
		id: 'REVISED_RESOLVE',
		name: 'Waiting for Approval',
		title: 'Pending Approval (Ongoing Case, Revision)'
	},
	BEING_REVIEWED_FOR_EDIT: {
		id: 'BEING_REVIEWED_FOR_EDIT',
		name: 'Waiting for Approval',
		title: 'Pending Approval (Response Update)'
	},
	EDIT_REJECTED: {
		id: 'EDIT_REJECTED',
		name: 'Processing',
		title: 'Rejected (Response Update)'
	},
	REVISED_EDIT: {
		id: 'REVISED_EDIT',
		name: 'Waiting for Approval',
		title: 'Pending Approval (Response Update, Revision)'
	},
	USER_UPDATED: {
		id: 'USER_UPDATED',
		name: 'Updated',
		title: 'Updated by Author'
	},
	UNCLAIMED: {
		id: 'UNCLAIMED',
		name: 'Unclaimed',
		title: 'Unclaimed'
	}
};
export const OPERATION_ACTION = {
	CLAIM: { id: 'CLAIM', name: 'Claim' },
	NO_ACT: { id: 'NO_ACT', name: 'Ignore' },
	REPLY: { id: 'REPLY', name: 'Reply' },
	REVISE: { id: 'REVISE', name: 'Revise' },
	EDIT: { id: 'EDIT', name: 'Edit Reply' },
	APPROVE: { id: 'APPROVE', name: 'Approve' },
	REJECT: { id: 'REJECT', name: 'Reject' },
	INIT: { id: 'INIT', name: 'Initialize' },
	REASSIGN: { id: 'REASSIGN', name: 'Reassign' },
	UNCLAIM: { id: 'UNCLAIM', name: 'UNCLAIM' }
};
export const OPERATION_REQUEST_STATUS = {
	SUCCESS: { id: 'SUCCESS', name: 'Success' },
	ERROR: { id: 'ERROR', name: 'Failed' }
};
export const POSTS_DEFAULT_REQUEST: SocialGetPostsRequestInterface = {
	q: '',
	from: new Date(new Date().getTime() - 518400000),
	to: new Date(),
	offset_start: 0,
	offset_end: TABLE_PAGELENGTH - 1,
	source: Object.values(SOCIAL_PLATFORMS)[0].id,
	filter: {
		min_rating: 0,
		max_rating: 5
	},
	criterias: {
		filters: [],
		sort: {
			id: '',
			order: 'asc'
		}
	}
};
export const CASES_DEFAULT_REQUEST: SocialGetCasesRequestInterface = {
	q: '',
	from: new Date(new Date().getTime() - 518400000),
	to: new Date(),
	offset_start: 0,
	offset_end: TABLE_PAGELENGTH - 1,
	source: Object.values(SOCIAL_PLATFORMS)[0].id,
	agent_id: 'agent1',	// TODO: Actual ID
	filter: {
		status: CASES_STATUS_FILTER.filter(e => e.key === 'awaiting_approval')[0].value as string[],	// supervisor
		agent_id: '',
		min_rating: 0,
		max_rating: 5
	}
};
export const POSTS_VALUE_TYPES = [
	{ id: 'postDate',         alt_id: 'post_datetime', type: 'date' },
	{ id: 'appVersion',       alt_id: 'mobile_version', type: 'string' },
	{ id: 'title',            alt_id: 'title', type: 'string' },
	{ id: 'text',             alt_id: 'text', type: 'string' },
	{ id: 'rating',           alt_id: 'rating', type: 'number', limit: { min: 0, max: 5 } },
	{ id: 'author',           alt_id: 'author', type: 'string' },
	{ id: 'slaTimeRemaining', alt_id: 'sla', type: 'time', limit: { min: 0, max: 14400 } }
];
export const CASES_VALUE_TYPES = [
	{ id: 'postDate',         alt_id: 'post_datetime', type: 'date' },
	{ id: 'latestUpdateDate', alt_id: 'history_datetime', type: 'date' },
	{ id: 'rating',           alt_id: 'rating', type: 'number', limit: { min: 0, max: 5 } },
	{
		id: 'status',
		alt_id: 'status',
		type: 'enum',
		values: CASES_STATUS_FILTER
			.filter(e => e.filter)
			.map(e => ({
				key: e.key,
				value: e.key,
				text: e.text
		}))
	},
	{ id: 'author',           alt_id: 'author', type: 'string' },
	{ id: 'text',             alt_id: 'text', type: 'string' },
	{ id: 'operator',         alt_id: 'staff_name', type: 'string' },
	{ id: 'slaTimeRemaining', alt_id: 'sla', type: 'time', limit: { min: 0, max: 14400 } }
];