import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError, WrappedResponse } from "../../models/http";
import { SocialSubmitActionRequestInterface, SocialSubmitActionResponseInterface } from "../models/operation-submit-action";
import { OPERATION_ROUTE } from "../configs/operation-api";

export const submitAction = async (payload: SocialSubmitActionRequestInterface): Promise<SocialSubmitActionResponseInterface> => {
	const response =
		await axios.post
			<WrappedResponse<SocialSubmitActionResponseInterface>>(
				OPERATION_ROUTE.SUBMIT_ACTION,
				payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data.responsePayload;
};