import { useRecoilState } from "recoil";
import { CASES_STATUS_FILTER } from "../configs/operation-ui";
import { StatusFilterInterface } from "../models/status-filter";
import { operationStatusFilter } from "../recoil-atoms/operation";

export const useStatusFilter = (): {
	get: StatusFilterInterface[],
	set: (newStatus: string[]) => void
} => {
	const [status, setStatus] = useRecoilState(operationStatusFilter);

	return {
		get: status,
		set: (newStatus: string[]) => {
			const result = newStatus.map(e => CASES_STATUS_FILTER.find(ee => ee.key === e))
			if (result)
				setStatus(result as StatusFilterInterface[]);
		}
	};
}