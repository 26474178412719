import React from "react";
import { Modal, Button } from "react-bootstrap";

class ConfirmModal extends React.Component<any> {
  render() {
    return (
      <Modal
        show={true}
        size={this.props.size}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
              {this.props.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>{this.props.topic}</h4>
            <p>{this.props.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant={this.props.button1Style}
              onClick={this.props.callBack1}
            >
              {this.props.button1}
            </Button>
            <Button
              variant={this.props.button2Style}
              onClick={this.props.callBack2}
            >
              {this.props.button2}
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default ConfirmModal;
