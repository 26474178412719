import { useRecoilState } from "recoil";
import { getUserData as APIGetUserData } from "../../actions/getUserData";
import { SocialGetUserDataResponseInterface } from "../../models/operation-get-user-data";
import { operationGetUserData } from "../../recoil-atoms/operation";

export const useGetUserData = () : {
	get: SocialGetUserDataResponseInterface,
	set: (posts: SocialGetUserDataResponseInterface) => void,
	request: () => Promise<SocialGetUserDataResponseInterface>,
} => {
	const [userData, setUserData] = useRecoilState(operationGetUserData);

	const httpGetUserData = async () => {
		const resp = await APIGetUserData();

		setUserData(resp);
		return resp;
	};

	return {
		get: userData,
		set: setUserData,
		request: httpGetUserData,
	};
}