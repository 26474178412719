import { useState } from "react";
import { createAnswer } from "../../actions/create";
import { PredefinedAnswerAction, PredefinedAnswersCreateRequest } from "../../models/payload-parts";

export type CreateAnswerHook = {
	get: PredefinedAnswerAction,
	set: (posts: PredefinedAnswerAction) => void,
	request: (payload: PredefinedAnswersCreateRequest) => Promise<PredefinedAnswerAction>,
};

export const useCreateAnswer = (): CreateAnswerHook => {
	const [actionResult, setActionResult] = useState({} as PredefinedAnswerAction);

	const httpGetAnswerList = async (payload: PredefinedAnswersCreateRequest) => {
		const resp: PredefinedAnswerAction = await createAnswer(payload);

		setActionResult(resp);
		return resp;
	};

	return {
		get: actionResult,
		set: setActionResult,
		request: httpGetAnswerList,
	};
}