import React from "react";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Header, Label, Icon } from "semantic-ui-react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Brush,
} from "recharts";

class BigSentimentLineChart extends React.Component<any> {
  state = { isShowSuggest: false };
  [key: string]: any;

  componentDidMount() {
    this.ref = React.createRef();
  }

  renderClickToSaveSuggestion = () => {
    if (this.state.isShowSuggest)
      return (
        <Label size="mini" pointing="right">
          Click to save as picture
        </Label>
      );
    else return null;
  };

  showSuggestion = () => {
    this.setState({ isShowSuggest: true });
  };

  hideSuggestion = () => {
    this.setState({ isShowSuggest: false });
  };

  renderContent = (data: any) => {
    return Object.keys(data).map((key, idx) => {
      return (
        <Row key={`sntTime_${idx}`}>
          <Header as="h4">{key}</Header>
          <Col lg={12} md={12} xs={12}>
            <LineChart
              width={1000}
              height={350}
              data={data[key]}
              margin={{
                top: 5,
                right: 40,
                left: 0,
                bottom: 50,
              }}
              style={{ cursor: "pointer" }}
              onClick={(param) => {
                if (param) {
                  const bigTableFilter = {
                    overide_datetime: param.activeLabel.split("+")[0],
                    sentiment: key,
                  };
                  this.props.setToggleShowBigTableWithFilter(bigTableFilter);
                }
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="Tick"
                minTickGap={10}
                interval={"preserveStartEnd"}
                hide={false}
              />
              <YAxis />
              <Tooltip />
              <Legend />
              {Object.keys(this.props.colors).map((key) => {
                return (
                  <Line
                    key={`aggLine_${idx}_${key}`}
                    type="monotone"
                    dataKey={key}
                    stroke={this.props.colors[key]}
                  />
                );
              })}
              <Brush
                dataKey="Tick"
                height={30}
                stroke="#000000"
                y={310}
                startIndex={10}
                endIndex={20}
              >
                <LineChart>
                  {Object.keys(this.props.colors).map((key) => {
                    return (
                      <Line
                        key={`brushLine_${key}`}
                        type="monotone"
                        dataKey={key}
                        stroke={this.props.colors[key]}
                      />
                    );
                  })}
                </LineChart>
              </Brush>
            </LineChart>
          </Col>
        </Row>
      );
    });
  };

  render() {
    return (
      <Modal
        show={true}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <>
          <Modal.Header>
            <Modal.Title
              id="contained-modal-title-vcenter"
              style={{ width: "100%" }}
            >
              Sentimental Mention Count
              <div
                style={{
                  display: "inline-block",
                  float: "right",
                }}
              >
                {this.renderClickToSaveSuggestion()}
                <Icon
                  name="save"
                  floated="right"
                  size="small"
                  style={{ cursor: "pointer" }}
                  onMouseEnter={this.showSuggestion}
                  onMouseLeave={this.hideSuggestion}
                  onClick={() => {
                    this.props.exportAsJPEG(
                      this.ref,
                      "sentimentLine.jpeg",
                      "#FFFFFF"
                    );
                  }}
                />
                <Icon
                  name="compress"
                  size="small"
                  style={{ cursor: "pointer" }}
                  onClick={this.props.setToggleShowBigSentimentLineChart}
                />
              </div>
            </Modal.Title>
          </Modal.Header>
          <div style={{ overflow: "auto", width: "100%", height: "60vh" }}>
            <Container
              ref={this.ref}
              style={{
                marginLeft: "0px",
                marginTop: "10px",
              }}
            >
              {this.renderContent(this.props.data)}
            </Container>
          </div>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={this.props.setToggleShowBigSentimentLineChart}
            >
              Close
            </Button>
          </Modal.Footer>
        </>
      </Modal>
    );
  }
}

export default BigSentimentLineChart;
