import React, {
	Attributes,
	ReactNode,
	useEffect,
	useLayoutEffect,
	useState
} from "react";

import {
	Header,
	Icon,
	Button,
	Pagination,
	Segment,
	Table,
	Loader,
	Modal,
	Message,
	Form,
	Select,
	FormField,
	Label,
	DropdownItemProps
} from "semantic-ui-react";
import { DateRangePicker, RangeKeyDict } from "react-date-range";
import { useLoadingModal } from "../hooks/operation-loading-modal";
import { DateRangeInterface } from "../models/date-range";
import { th } from "date-fns/locale";
import { StateHook } from "../models/known-types";

export const DataTable = (props: {
	columns: NodeJS.Dict<string>,
	data: NodeJS.Dict<unknown>[],
	paginationService?: ({ pageOffset, data }: { // for API calls on page change. If this prop is set, it is service-bound.
		pageOffset: number,
		data?: NodeJS.Dict<unknown>[]
	}) => Promise<unknown>,
	pageLength?: number, // rows per page
	totalDataLength?: number, // service-bound only
	rowComponents?: (React.ReactElement<unknown> | React.ReactElement<unknown>[]),
	rowClick?: ({}: { // row click event handler
		event?: Event,
		offset?: number,
		index?: number,
	}) => unknown,
	rowProps?: NodeJS.Dict<unknown> | NodeJS.Dict<unknown>[],
	[key: string]: unknown
}): JSX.Element => {
	const loadingModal = useLoadingModal();

	const [pageOffset, setPageOffset] = useState(0);
	const [pageData, setPageData] = useState<NodeJS.Dict<unknown>[]>([]);
	const [changingPage, setChangingPage] = useState(false);
	
	const externalDataChange = () => {
		if (props.paginationService) {
			setPageData(props.data);
			if (!changingPage)
				setPageOffset(0);
		}
		else
			setPageData(props.data.slice(0, props.pageLength));
	};
	const createRowComponents = (rowIndex: number) => {
		if (props.rowComponents)
			if (!Array.isArray(props.rowComponents))
				return (
					<Table.Cell>
						{React.cloneElement(props.rowComponents)}
					</Table.Cell>
				);
			else
				return props.rowComponents.map((e, i) => (
					<Table.Cell key={i}>
						{React.cloneElement(e, { rowIndex: rowIndex } as Attributes)}
					</Table.Cell>
				));
	};
	const createDataRow = (data:NodeJS.Dict<unknown>[]) => {
		return data.map((e, i) => (
			<Table.Row
				key={i}
				onClick={
					(evt: Event) => props.rowClick ? props.rowClick({
						event: evt,
						index: i,
						offset: i + pageOffset
					}) : undefined
				}
				{
					...props.rowProps ?
						Array.isArray(props.rowProps) ?
							props.rowProps[i] : props.rowProps
					: ''
				}
			>
				{Object.keys(props.columns).map((id, j) => <Table.Cell key={j}>{e[id] as ReactNode}</Table.Cell>)}
				{props.rowComponents ? createRowComponents(i + pageOffset): ''}
			</Table.Row>
		));
	};

	const changePage = async (offset: number) => {
		setPageOffset(offset);
		if (props.paginationService) {
			setChangingPage(true);
			loadingModal.addTask('changePage');

			await props.paginationService({
				pageOffset: offset,
				data: props.data
			});

			setChangingPage(false);
			loadingModal.removeTask('changePage');
		}
		else
			setPageData(props.data.slice(offset, props.pageLength ? offset + props.pageLength : undefined));
	};

	useLayoutEffect(() => externalDataChange(), [props.data]);
	useLayoutEffect(() => setPageOffset(0), [props.pageLength]);

	return <Table>
		<Table.Header>
			<Table.Row>
				{Object.values(props.columns).map((label, i) =>
					<Table.HeaderCell key={i}>{label}</Table.HeaderCell>
				)}
				<Table.HeaderCell></Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<Table.Body>
			{createDataRow(pageData)}
		</Table.Body>
		{ props.pageLength ?
			<Table.Footer>
				<Table.Row>
					<Table.HeaderCell colSpan={99}>
						<Pagination
							activePage={(() => {
								return Math.floor(pageOffset / props.pageLength) + 1;
							})()}
							boundaryRange={1}
							siblingRange={1}
							totalPages={Math.ceil((props.totalDataLength ? props.totalDataLength : props.data.length) / props.pageLength)}
							floated="right"
							onPageChange={(e, data) => {
								if (props.pageLength) {
									const offset = ((data.activePage as number) - 1) * props.pageLength;
									changePage(offset);
								}
							}}
						/>
					</Table.HeaderCell>
				</Table.Row>
			</Table.Footer> :
			''
		}
	</Table>;
};

export const SearchPanelPlaceholder = (): JSX.Element => (
	<Segment placeholder>
		<Header as="h2" icon>
			<Icon name="search"/>
			<i>[Insert a search form here]</i>
		</Header>
	</Segment>
);

export const LoadingOverlay = (): JSX.Element => <Loader active inline='centered'/>;

export const DatePickerButton = (
	props: React.PropsWithChildren<{
		className?: string,
		inForm?: boolean,
		modalLabel?: string | JSX.Element,
		range: StateHook<DateRangeInterface>,
		defaultRange: DateRangeInterface,
		onConfirm?: () => unknown,
		confirmButtonContent?: string | JSX.Element,
		[key:string]: unknown
	}>
): JSX.Element => {
	const [initialized, setInitialized] = useState(false);
	const [dateModalVisible, toggleDateModal] = useState(false);
	const [externalDateRange, setExternalDateRange] = props.range;
	const [selectedDateRange, setSelectedDateRange] = useState<DateRangeInterface>(props.defaultRange);
	const [prevDateRange, setPrevDateRange] = useState<DateRangeInterface>(props.defaultRange);

	const selectDateRange = (inputRange: RangeKeyDict) => {
		const firstRange = Object.values(inputRange)[0] as DateRangeInterface;
		if (firstRange.startDate)
			setSelectedDateRange({
				startDate: firstRange.startDate,
				endDate: firstRange.endDate
			});
	};
	const confirmDateRange = () => setExternalDateRange(Object.assign({}, selectedDateRange));
	const cancelDateRange = () => {
		setSelectedDateRange(prevDateRange);
		toggleDateModal(false);
	}

	useEffect(() => {
		setSelectedDateRange(externalDateRange);
		if (props.onConfirm && (dateModalVisible || !initialized)) {
			setInitialized(true);
			props.onConfirm();	
		}
		toggleDateModal(false);
	}, [externalDateRange])
	useEffect(() => {
		if (dateModalVisible) {
			setPrevDateRange(selectedDateRange);
		}
	}, [dateModalVisible]);

	return <>
		{
			props.inForm ?
				<Form.Button
					className={props.className ? "date-picker-button " + props.className: "date-picker-button"}
					icon="calendar"
					primary
					content={props.children}
					onClick={() => toggleDateModal(true)}
				/> :
				<Button
					className={'date-picker-button ' + (props.className ?? '')}
					icon="calendar"
					primary
					content={props.children}
					onClick={() => toggleDateModal(true)}
				/>
		}
		<Modal
			className="date-picker-modal"
			open={dateModalVisible}
			closeOnDimmerClick={false}
		>
			<Modal.Header>{props.modalLabel ? props.modalLabel: 'Select Date'}</Modal.Header>
			<Modal.Content>
				<DateRangePicker
					locale={th}
					ranges={[selectedDateRange]}
					onChange={selectDateRange}
					minDate={new Date(new Date().getTime() - 157852800000)} // 5 years back
					maxDate={new Date()}
					{...props}
				/>
			</Modal.Content>
			<Modal.Actions>
				<Button onClick={cancelDateRange} floated="left" color="red">Close</Button>
				<Button onClick={confirmDateRange} color="green">{props.confirmButtonContent ? props.confirmButtonContent: 'Set'}</Button>
			</Modal.Actions>
		</Modal>
	</>
};

export const GenericErrorMessage = ({message} : { message?: string }): JSX.Element => (
	<Message
	error
		icon="times circle"
		header="Failed"
		content={message ? message: "Could not complete the action. Please contact Innovation Team!"}
	/>
);

export const ReloadPageButton = (): JSX.Element => <Button primary onClick={() => window.location.reload()}>Reload Page</Button>

export const RatingRangeSelect = (props: {
	min: number,
	max: number,
	label?: ReactNode,
	onChange: (min: number, max: number) => void,
	[key: string]: unknown
}): JSX.Element => {
	const fullRatingOptions = [
		{ key: '0', value: 0, text: '0' },
		{ key: '1', value: 1, text: '1' },
		{ key: '2', value: 2, text: '2' },
		{ key: '3', value: 3, text: '3' },
		{ key: '4', value: 4, text: '4' },
		{ key: '5', value: 5, text: '5' }
	];
	const [minRatingOptions, setMinRatingOptions] = useState<DropdownItemProps[]>([]);
	const [maxRatingOptions, setMaxRatingOptions] = useState<DropdownItemProps[]>([]);

	useLayoutEffect(() => {
		setMinRatingOptions(fullRatingOptions.slice(0, 6));
		setMaxRatingOptions(fullRatingOptions.slice(0, 6));
	}, []);

	return (
		<FormField className="operation-rating-range-select">
			<Label>{props.label}</Label>
			<div>
				<Select
					fluid
					value={props.min}
					options={minRatingOptions}
					onChange={(e, target) => {
						const min = target.value as number;
						setMinRatingOptions(fullRatingOptions.slice(0, props.max + 1));
						setMaxRatingOptions(fullRatingOptions.slice(min));
						props.onChange(min, props.max);
					}}
				/>
				<span>to</span>
				<Select
					fluid
					value={props.max}
					options={maxRatingOptions}
					onChange={(e, target) => {
						const max = target.value as number;
						setMinRatingOptions(fullRatingOptions.slice(0, max + 1));
						setMaxRatingOptions(fullRatingOptions.slice(props.min));
						props.onChange(props.min, max);
					}}
				/>
			</div>
		</FormField>
	);
}