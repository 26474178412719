import { atom } from "recoil";
import { SocialGetPostsRequestInterface, SocialGetPostsResponseInterface } from "../models/operation-get-posts";
import { SocialGetCaseDetailsResponseInterface } from "../models/operation-get-case-details";
import { SocialGetCasesRequestInterface, SocialGetCasesResponseInterface } from "../models/operation-get-cases";
import { SOCIAL_PLATFORMS } from "../configs/operation-ui";
import { SocialReplyFormDataInterface, SocialSubmitActionResponseInterface } from "../models/operation-submit-action";
import { SocialGetUserDataResponseInterface } from "../models/operation-get-user-data";
import { SocialGetUsersResponseInterface } from "../models/operation-get-users";
import { StatusFilterInterface } from "../models/status-filter";

export const operationActionModalStack = (() => {
	interface OperationActionModalStackInterface {
		header?: string | JSX.Element | JSX.Element[],
		content: string | JSX.Element | JSX.Element[],
		actions?: JSX.Element | JSX.Element[],
		[key: string]: unknown
	}

	return atom({
		key: 'operationActionModalStack',
		default: [] as OperationActionModalStackInterface[]
	});
})();

export const operationActionDataStack = (() => {
	interface OperationActionDataStackInterface {
		action: string,
		data?: unknown
		[key: string]: unknown
	}

	return atom({
		key: 'operationActionDataStack',
		default: [] as OperationActionDataStackInterface[]
	});
})();

export const operationPostsPlatform = atom({ key: 'operationPostsPlatform', default: Object.values(SOCIAL_PLATFORMS)[0].id });
export const operationCasesPlatform = atom({ key: 'operationCasesPlatform', default: Object.values(SOCIAL_PLATFORMS)[0].id });
export const operationRole = atom({ key: 'operationRoleData', default: 'agent' as ('agent' | 'admin') });
export const operationLoadingModal = atom<{
	showLoading?: () => void,
	hideLoading?: () => void
}>({
	key: 'operationLoadingModal',
	default: {}
});
export const operationLoadingModalTasks = atom<string[]>({ key: 'operationLoadingModalTasks', default: [] })
export const operationLoadingModalDelayReached = atom({ key: 'operationLoadingModalDelayReached', default: true })
export const operationReplyForm = atom({
	key: 'operationReplyForm',
	default: {} as SocialReplyFormDataInterface
});

export const operationGetUserData = atom({ key: 'operationGetUserData', default: {} as SocialGetUserDataResponseInterface });
export const operationStatusFilter = atom({ key: 'operationStatusFilter', default: [] as StatusFilterInterface[] });

export const operationPostsColumnLabels = atom({ key: 'operationPostsColumnLabels', default: {} as { [key: string]: string } });
export const operationCasesColumnLabels = atom({ key: 'operationCasesColumnLabels', default: {} as { [key: string]: string } });

export const operationGetUsersResponse = atom({ key: 'operationGetUsersResponse', default: {} as SocialGetUsersResponseInterface });
export const operationGetPostsRequest = atom({ key: 'operationPostsRequest', default: {} as Partial<SocialGetPostsRequestInterface> });
export const operationGetPostsResponse = atom({ key: 'operationPostsResponse', default: {} as SocialGetPostsResponseInterface });
export const operationCaseDetailsResponse = atom({ key: 'operationCaseDetailsResponse', default: {} as SocialGetCaseDetailsResponseInterface });
export const operationGetCasesRequest = atom({ key: 'operationCasesRequest', default: {} as Partial<SocialGetCasesRequestInterface> });
export const operationGetCasesResponse = atom({ key: 'operationCasesResponse', default: {} as SocialGetCasesResponseInterface });
export const operationSubmitActionResponse = atom({ key: 'operationSubmitActionResponse', default: {} as SocialSubmitActionResponseInterface });