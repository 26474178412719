import React from "react";
import { connect } from "react-redux";
import { Collapse, Navbar, Nav, NavDropdown } from "react-bootstrap";
import { changePage, logout } from "../actions";
import { Button, Icon } from "semantic-ui-react";
import { OperationCurrentRoleLabel } from "../social-operation/components/operation";
import { OPERATION_USER_ROLE } from "../social-operation/configs/operation-ui";
// import ConfirmModal from "./ConfirmModal";

class Header extends React.Component<any> {
  state = { open: false };

  componentDidMount() {
    this.setState({ open: true });
  }

  renderMenu = () => {
    if (this.props.loginUser.data) {
      const userData = this.props.loginUser.data;
      return (
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            {userData.role ?
              userData.role === OPERATION_USER_ROLE.SUPERVISOR.id ?
                <>
                <Nav.Link
                  active
                  onClick={() => {
                    this.props.changePage("Operation");
                  }}
                  href="#Operation"
                >
                  Operation
                </Nav.Link>
                <Nav.Link
                  active
                  onClick={() => {
                    this.props.changePage("AnswersConfig");
                  }}
                  href="#AnswersConfig"
                >
                  Predefined Answers
                </Nav.Link>
                </>
                :
                <Nav.Link
                  active
                  onClick={() => {
                    this.props.changePage("Operation");
                  }}
                  href="#Operation"
                >
                  Operation
                </Nav.Link>
              :
              <>
                <Nav.Link
                  onClick={() => {
                    this.props.changePage("Home");
                  }}
                  href="#Home"
                >
                  Home
                </Nav.Link>
                <Nav.Link
                  onClick={() => {
                    this.props.changePage("Dashboard");
                  }}
                  href="#Dashboard"
                >
                  Dashboard
                </Nav.Link>
              </>
            }
            {!userData.role ?
              <NavDropdown title="Control" id="collasible-nav-dropdown">
                <NavDropdown.Item
                  onClick={() => {
                    this.props.changePage("Manage");
                  }}
                  href="#Manage"
                >
                  Manage Dashboard
                </NavDropdown.Item>
              </NavDropdown>
              :
              <></>
            }
          </Nav>
          <Nav>
            <Navbar.Text className="user-info">
              Signed in as:
              <a
                style={{ marginLeft: "5px" }}
                className={"userEmail"}
                href="#login"
              >
                {userData.emails[0].value}
              </a>
              <br/>
              <OperationCurrentRoleLabel/>
            </Navbar.Text>
          </Nav>
          <Nav>
            <Nav.Link href="#logout">
              <Button
                fluid
                color="google plus"
                onClick={() => {
                  this.props.logout();
                }}
              >
                <Icon name="sign out alternate" /> Sign out
              </Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      );
    } else {
      return null;
    }
  };

  render() {
    return (
      <div>
        <Collapse in={this.state.open}>
          <Navbar
            collapseOnSelect
            expand="lg"
            bg="dark"
            variant="dark"
            fixed="top"
          >
            <Navbar.Brand
              href="#home"
              onClick={() => {
                this.props.changePage("Home");
              }}
            >
              Social Monitoring Tools
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            {this.renderMenu()}
          </Navbar>
        </Collapse>
        {/* {this.renderConfirmLogout()} */}
      </div>
    );
  }
}
const mapStateToProps = (states: any) => {
  return {
    loginUser: states.loginUser,
  };
};
export default connect(mapStateToProps, { changePage, logout })(Header);
