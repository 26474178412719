import { useState } from "react";
import { deleteAnswer } from "../../actions/delete";
import { PredefinedAnswerAction, PredefinedAnswersDeleteRequest } from "../../models/payload-parts";

export type DeleteAnswerHook = {
	get: PredefinedAnswerAction,
	set: (posts: PredefinedAnswerAction) => void,
	request: (payload: PredefinedAnswersDeleteRequest) => Promise<PredefinedAnswerAction>,
};

export const useDeleteAnswer = (): DeleteAnswerHook => {
	const [actionResult, setActionResult] = useState({} as PredefinedAnswerAction);

	const httpGetAnswerList = async (payload: PredefinedAnswersDeleteRequest) => {
		const resp: PredefinedAnswerAction = await deleteAnswer(payload);

		setActionResult(resp);
		return resp;
	};

	return {
		get: actionResult,
		set: setActionResult,
		request: httpGetAnswerList,
	};
}