import { useRecoilState } from "recoil";
import { getCases as APIGetCases } from "../../actions/getCases";
import { SocialGetCasesRequestInterface, SocialGetCasesResponseInterface } from "../../models/operation-get-cases";
import { operationGetCasesRequest, operationGetCasesResponse } from "../../recoil-atoms/operation";

export const useViewCases = () : {
	get: SocialGetCasesResponseInterface,
	set: (posts: SocialGetCasesResponseInterface) => void,
	request: (payload: Partial<SocialGetCasesRequestInterface>, payloadFill?: boolean) => Promise<SocialGetCasesResponseInterface>,
} => {
	const [currentPayload, setCurrentPayload] = useRecoilState(operationGetCasesRequest);
	const [posts, setPosts] = useRecoilState(operationGetCasesResponse);

	const httpGetCases = async (payload: Partial<SocialGetCasesRequestInterface>, payloadFill?: boolean) => {
		const newPayload: Partial<SocialGetCasesRequestInterface> = payloadFill ? Object.assign({}, currentPayload, payload) : payload;

		const response: SocialGetCasesResponseInterface = await APIGetCases(newPayload as SocialGetCasesRequestInterface);
		setPosts(response);

		setCurrentPayload(newPayload);
		return response;
	};

	return {
		get: posts,
		set: setPosts,
		request: httpGetCases,
	};
}