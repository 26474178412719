import axios from "axios";
import { getReasonPhrase, StatusCodes } from "http-status-codes";
import { debug } from "debug";
import { HTTPRequestError } from "../../models/http";
import { PredefinedAnswersCreateRequest, PredefinedAnswersActionResponse, PredefinedAnswerAction } from "../models/payload-parts";
import { PREDEF_ANSWERS_ROUTE } from "../configs/predef-answers-api";

export const createAnswer =
	async (payload: PredefinedAnswersCreateRequest) :
	Promise<PredefinedAnswerAction> =>
{
	const response =
		await axios.put
			<PredefinedAnswersActionResponse>(
				PREDEF_ANSWERS_ROUTE.CREATE,
				payload);

	if (response.status !== StatusCodes.OK) {
		debug(`Error: ${response.status}`);
		throw new HTTPRequestError(getReasonPhrase(response.status));
	}

	return response.data.responsePayload;
};