import { StatusFilterInterface } from "../models/status-filter";

interface SocialPlatformInterface {
	id: string,
	name: string,
	icon: string,
	maxLength: { [key: string]: number }
}

export const TABLE_PAGELENGTH = 20;
export const QUICK_PREDEF_ANSWER_COUNT = 3;
export const SOCIAL_PLATFORMS: { [key: string]: SocialPlatformInterface } = {
	PLAY_STORE: {
		id: 'play_store',
		name: 'Google Play Store',
		icon: 'google play',
		maxLength: {
			reply: 350
		}
	},
	APP_STORE: {
		id: 'app_store',
		name: 'App Store',
		icon: 'apple',
		maxLength: {
			reply: 5970
		}
	},
};
export const OPERATION_USER_ROLE = {
	AGENT: { id: 'agent', name: 'Agent' },
	SUPERVISOR: { id: 'supervisor', name: 'Supervisor' }
};
export const CASES_STATUS_FILTER: StatusFilterInterface[] = [
	{
		key: 'processing',
		value: [
			'CLAIMED',
			'REJECTED',
			'NEED_ACTION',
			'RESOLVE_REJECTED',
			'EDIT_REJECTED'
		],
		text: 'Processing',
		filter: true
	},
	{
		key: 'awaiting_approval',
		value: [
			'BEING_REVIEWED',
			'REVISED',
			'BEING_REVIEWED_FOR_NO_ACT',
			'BEING_REVIEWED_FOR_RESOLVE',
			'REVISED_RESOLVE',
			'BEING_REVIEWED_FOR_EDIT',
			'REVISED_EDIT'
		],
		text: 'Waiting for Approval',
		filter: true
	},
	{
		key: 'completed',
		value: [ 'CLOSED' ],
		text: 'Completed',
		filter: true
	},
	{
		key: 'closed',
		value: [ 'CLOSED_WITH_NO_ACT' ],
		text: 'Closed',
		filter: true
	},
	{
		key: 'updated',
		value: [ 'USER_UPDATED' ],
		text: 'Updated',
		filter: false
	},
	{
		key: 'follow_up',
		value: [ 'BEING_RESOLVED' ],
		text: 'Follow Up',
		filter: true
	}
];
export const CASES_CATEGORY = {
	INFO: {
		key: 'info',
		text: 'ข้อมูล/ขั้นตอนการใช้งาน',
		value: 1
	},
	PLANNED: {
		key: 'planned',
		text: 'อยู่ในแผนการพัฒนาแล้ว',
		value: 2
	},
	NOT_PLANNED: {
		key: 'notPlanned',
		text: 'เรื่องใหม่ที่ยังไม่มีแผน',
		value: 3
	},
	UNCLEAR: {
		key: 'unclear',
		text: 'ลูกค้าให้ข้อมูลไม่ชัดเจน',
		value: 4
	},
	RESOLVING: {
		key: 'resolving',
		text: 'ปัญหาที่ธนาคารกำลังแก้ไข',
		value: 5
	},
	RESOLVED: {
		key: 'resolved',
		text: 'ปัญหาที่ธนาคารแก้ไขแล้ว',
		value: 6
	},
	NEW_CASE: {
		key: 'newCase',
		text: 'ปัญหาใหม่',
		value: 7
	},
	MAINTENANCE: {
		key: 'maintenance',
		text: 'ธนาคารปิดระบบ',
		value: 8
	},
	COMPLAIN: {
		key: 'complain',
		text: 'บ่น/ด่าโดยไม่มีการสอบถาม/ร้องเรียน',
		value: 9
	},
	COMPLIMENT: {
		key: 'compliment',
		text: 'ชมเชย',
		value: 10
	},
	ENGLISH: {
		key: 'english',
		text: 'Caseภาษาอังกฤษ',
		value: 11
	},
	ILLEGIBLE: {
		key: 'illegible',
		text: 'อ่านไม่ออก/ภาษาอื่นๆ/อื่นๆ',
		value: 12
	},
	OTHER: {
		key: 'other',
		text: 'อื่นๆ',
		value: 13
	},
	ENGLISH_AGENT: {
		key: 'english_agent',
		text: 'Caseภาษาอังกฤษ/Agent',
		value: 14
	},
};
export const CASES_ISSUE_TYPE = {
	INQUIRY: {
		key: 'inquiry',
		text: 'Inquiry',
		value: 1,
		categories: [
			CASES_CATEGORY.INFO,
			CASES_CATEGORY.OTHER
		]
	},
	FEEDBACK: {
		key: 'feedback',
		text: 'Feedback',
		value: 2,
		categories: [
			CASES_CATEGORY.PLANNED,
			CASES_CATEGORY.NOT_PLANNED,
			CASES_CATEGORY.UNCLEAR
		]
	},
	COMPLAINT: {
		key: 'complaint',
		text: 'Complaint',
		value: 3,
		categories: [
			CASES_CATEGORY.RESOLVING,
			CASES_CATEGORY.RESOLVED,
			CASES_CATEGORY.NEW_CASE,
			CASES_CATEGORY.UNCLEAR,
			CASES_CATEGORY.MAINTENANCE,
			CASES_CATEGORY.COMPLAIN,
			CASES_CATEGORY.OTHER
		]
	},
	COMPLIMENT: {
		key: 'compliment',
		text: 'Compliment',
		value: 4,
		categories: [
			CASES_CATEGORY.COMPLIMENT
		]
	},
	OTHER: {
		key: 'other',
		text: 'Other',
		value: 5,
		categories: [
			CASES_CATEGORY.ENGLISH,
			CASES_CATEGORY.ILLEGIBLE,
			CASES_CATEGORY.ENGLISH_AGENT,
		]
	}
};
export const CASES_SENTIMENT = {
	HAPPY: {
		key: 'happy',
		text: 'Happy',
		className: 'case-sentiment-happy',
		value: 1
	},
	SATISFIED: {
		key: 'neutral',
		text: 'Neutral',
		className: 'case-sentiment-neutral',
		value: 2
	},
	UNHAPPY: {
		key: 'unhappy',
		text: 'Unhappy',
		className: 'case-sentiment-unhappy',
		value: 3
	}
};